import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { ProjectContext } from 'contexts/ProjectContext';
import { Population } from 'graphql/generated';
import { useContext, useMemo } from 'react';
import { Box, HStack, Skeleton, Text, VStack } from '@chakra-ui/react';
import { Notice } from 'components/Notice';
import TotalFish from 'components/Charts/TotalFish/Chart';
import { QueryContextProvider } from 'contexts/QueryContext';
import { sortBy } from 'lodash';
import { max, min } from 'date-fns';

const POPULATION_COUNT_ANALYSIS_QUERY = gql`
  query PopulationCountAnalysisQuery($populationsWhere: PopulationWhere!) {
    populations(where: $populationsWhere) {
      SmbId
      PopulationId
      StartTime
      EndTime
      Tags
      relatedHarvestsConnection {
        edges {
          properties {
            TransferredBiomassKg
            TransferredAvgBiomassKg
            TransferredCount
            SourcePop
            DestPop
            Tags
          }
          node {
            StartTime
          }
        }
      }
      relatedInputsConnection {
        edges {
          properties {
            TransferredBiomassKg
            TransferredAvgBiomassKg
            TransferredCount
            SourcePop
            DestPop
            Tags
          }
          node {
            StartTime
          }
        }
      }
      relatedPopulationsInConnection {
        edges {
          properties {
            TransferredBiomassKg
            TransferredAvgBiomassKg
            TransferredCount
            SourcePop
            DestPop
            Tags
          }
          node {
            PopulationId
            Container
            StartTime
            EndTime
            Tags
            site {
              id
              name
            }
          }
        }
      }
      relatedPopulationsOutConnection {
        edges {
          properties {
            TransferredBiomassKg
            TransferredAvgBiomassKg
            TransferredCount
            SourcePop
            DestPop
            Tags
          }
          node {
            PopulationId
            Container
            StartTime
            EndTime
            Tags
            site {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const PopulationAnalysisId = () => {
  const { siteId, sublocationId } = useParams();
  const project = useContext(ProjectContext);

  const {
    data: populationData,
    loading,
    error
  } = useQuery<{ populations: Population[] }>(POPULATION_COUNT_ANALYSIS_QUERY, {
    variables: {
      populationsWhere: {
        SmbId: project.sites.find((s) => s.id === Number(siteId)).smbId,
        Container: sublocationId
      }
    }
  });

  const populationsIn = useMemo(() => {
    if (!populationData) return [];

    return populationData.populations.flatMap((p) => p.relatedPopulationsInConnection.edges);
  }, [populationData]);

  if (loading) {
    return <Skeleton w="100%" h="500px" />;
  }

  if (error) {
    return <Notice>There was a problem loading population data...</Notice>;
  }

  const sublocationFormat = `cage-${sublocationId}`.replace(/-(0+)(\d+)/, (_, _zeroes, digits) => {
    const number = parseInt(digits, 10);
    return number < 10 ? `-0${number}` : `-${number}`;
  });

  return (
    <VStack w="100%">
      <h1>
        {project.sites.find((s) => s.id === Number(siteId)).name} - {sublocationFormat}
      </h1>
      {sortBy(populationsIn, 'node.EndTime').map((pi, i) => (
        <Box w="100%" key={i}>
          <HStack w="100%" justifyContent={'space-between'}>
            <HStack>
              <Text>End Time:</Text>
              <Text>{pi.node.EndTime}</Text>
            </HStack>
            <HStack>
              <Text>Count:</Text>
              <Text>{pi.properties.TransferredCount}</Text>
            </HStack>
            <HStack>
              <Text>Biomass total:</Text>
              <Text>{pi.properties.TransferredBiomassKg}</Text>
            </HStack>
          </HStack>
        </Box>
      ))}
      <QueryContextProvider
        // site={project.sites.find((s) => s.id === Number(siteId))}
        fishGroupSpans={[
          {
            start: min(populationsIn.map((pi) => new Date(pi.node.StartTime))),
            end: max(populationsIn.map((pi) => new Date(pi.node.EndTime))),
            siteSmbId: project.sites.find((s) => s.id === Number(siteId)).smbId,
            sublocation: sublocationFormat
          }
        ]}>
        <TotalFish
          settings={{
            site: project.sites.find((s) => s.id === Number(siteId)),
            project
          }}
        />
      </QueryContextProvider>
    </VStack>
  );
};

export default PopulationAnalysisId;
