import {
  VStack,
  HStack,
  Text,
  TabList,
  Tabs,
  Tab,
  ButtonGroup,
  Button,
  useToast
} from '@chakra-ui/react';
import TabHeadline from 'components/TabHeadline';
import { Outlet, useNavigate } from 'react-router-dom';
import { TbReport } from 'react-icons/tb';
import { Link as ReactLink } from 'react-router-dom';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import { AddIcon } from '@chakra-ui/icons';
import { UserContext } from 'contexts/UserContext';
import { permissions } from '@scoot/permissions';

const CREATE_PROJECT_REPORT = gql`
  mutation CreateProjectReport($projectId: Int!) {
    createProjectReport(projectId: $projectId)
  }
`;

// Placeholder for now.
// I tried making a layout here, but was never happy with it.
const ReportingLayout = () => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);
  const toast = useToast();
  const [createProjectReport] = useMutation(CREATE_PROJECT_REPORT);
  const navigate = useNavigate();

  const createReportAndNavigate = async () => {
    try {
      const resp = await createProjectReport({ variables: { projectId: project.id } });
      navigate(`/project/${project.id}/reports/${resp.data.createProjectReport}/edit/details`);
    } catch (e) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error creating report'
      });
    }
  };

  return (
    <VStack w="100%" mb="2rem">
      <HStack alignItems={'start'}>
        <Text fontSize="4xl" mt="1em">
          Reports{' '}
        </Text>
        <Text color="blue.500" fontSize="xs" pt="40px">
          New!
        </Text>
      </HStack>
      <TabHeadline
        icon={<TbReport />}
        text="Generate reports and documents for external reporting and management."
      />

      <ButtonGroup py="20px" alignSelf="start">
        <Button
          data-cypress="new-report"
          colorScheme="blue"
          leftIcon={<AddIcon />}
          onClick={() => createReportAndNavigate()}>
          New Report
        </Button>
      </ButtonGroup>

      <Tabs w="100%">
        <TabList>
          <ReactLink to={`/project/${project.id}/reports`}>
            <Tab>My Reports</Tab>
          </ReactLink>
          <ReactLink data-cypress="shared-reports-tab" to={`/project/${project.id}/reports/shared`}>
            <Tab>Shared Reports</Tab>
          </ReactLink>
          {permissions.canAdminProject(user, project.id) && (
            <ReactLink to={`/project/${project.id}/reports/all`}>
              <Tab>All Reports</Tab>
            </ReactLink>
          )}
        </TabList>

        <Outlet />
      </Tabs>
    </VStack>
  );
};

export default ReportingLayout;
