import { VStack } from '@chakra-ui/react';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { ReportingEditProvider } from 'components/Reporting/ReportingEditContext';
import ReportStepper from 'components/Reporting/ReportStepper';
import ReportNav from 'components/Reporting/ReportNav';
import ProjectReportingEditContent from 'components/Reporting/ProjectReportingEditContent';

const ReportingIdEdit = () => {
  const { reportId, step } = useParams();
  const project = useContext(ProjectContext);

  if (!step) {
    <Navigate replace={true} to={`/project/${project.id}/reports/${reportId}/edit/select`} />;
  }

  return (
    <ReportingEditProvider stepSlug={step} reportId={Number(reportId)}>
      <VStack my="20px" w="100%">
        <ReportStepper />

        <ProjectReportingEditContent />

        <ReportNav />
      </VStack>
    </ReportingEditProvider>
  );
};

export default ReportingIdEdit;
