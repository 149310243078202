import { gql, useMutation, useQuery } from '@apollo/client';
import { AddIcon, ArrowLeftIcon, ArrowRightIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Skeleton,
  Switch,
  Text,
  Tooltip,
  VStack,
  useToast
} from '@chakra-ui/react';
import { BinaryFilter, DateRange, Query, TimeDimensionGranularity } from '@cubejs-client/core';
import { permissions } from '@scoot/permissions';
import FormTimeFragmentPicker from 'components/Charts/Custom/FormTimeFragmentPicker';
import { TimeFragment } from 'components/Charts/Custom/TimeFragmentPicker';
import { BaseChartSettings, InflatedChart } from 'components/Charts/types';
import InfoPopover from 'components/InfoPopover';
import ConfirmDeleteAlert from 'components/Modals/ConfirmDeleteAlert';
import { Notice } from 'components/Notice';
import TabHeadline from 'components/TabHeadline';
import Tile from 'components/Tile';
import { ProjectContextType } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { format, max, min } from 'date-fns';
import {
  ChartEventsQuery,
  ChartSet,
  DeleteChartSetMutation,
  DeleteChartSetMutationVariables,
  GetChartSetsQuery,
  SaveChartSetMutation,
  SaveChartSetMutationVariables,
  Site
} from 'graphql/generated';
import { fill, isArray, isEmpty, omit, uniq } from 'lodash';
import { FC, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { IoMdSave } from 'react-icons/io';
import { SiWpexplorer } from 'react-icons/si';
import { useSearchParams } from 'react-router-dom';
import { toCsvZip } from 'shared/functions/fileExports';
import ChartEditorModal from './ChartEditorModal';
import { ChartSetModal } from './ChartSetModal';
import InflatedChartRender from './InflatedChartRender';
import useExploreCharts from './useExploreCharts';

const GET_CHART_EVENTS = gql`
  query ChartEvents($siteId: Int!, $startTime: Date, $endTime: Date) {
    getEvents(siteId: $siteId, startTime: $startTime, endTime: $endTime) {
      id
      eventType
      startTime
      endTime
    }
  }
`;

const DELETE_CHART_SET = gql`
  mutation DeleteChartSet($id: Int!) {
    deleteChartSet(id: $id)
  }
`;

const GET_CHART_SETS = gql`
  query GetChartSets($projectId: Int!, $isProjectSet: Boolean!) {
    chartSets(projectId: $projectId, isProjectSet: $isProjectSet) {
      id
      name
      description
      isProjectSet
      timeRange {
        startTime
        endTime
        granularity
        dateRange
      }
      charts {
        id
        chartId
        settings
        displayName
      }
    }
  }
`;

const SAVE_CHART_SET = gql`
  mutation SaveChartSet($id: Int, $projectId: Int!, $chartSet: ChartSetInput!) {
    saveChartSet(id: $id, projectId: $projectId, chartSet: $chartSet)
  }
`;

type ExploreProps = {
  selected: boolean;
  site?: Site;
  project?: ProjectContextType;
};

// Yoink: https://github.com/chakra-ui/chakra-ui/issues/4202#issuecomment-1167487676
const OverflownText: FC = ({ children, ...props }) => {
  const ref = useRef(null);
  const [isOverflown, setIsOverflown] = useState(false);
  useEffect(() => {
    const element = ref.current!;
    setIsOverflown(element.scrollWidth > element.clientWidth);
  }, []);

  return (
    <Tooltip label={children} isDisabled={!isOverflown}>
      <Box position="relative" isTruncated ref={ref} {...props}>
        {children}
      </Box>
    </Tooltip>
  );
};

type ChartEventContextType = {
  events: ChartEventsQuery['getEvents'];
  showEvents: boolean;
};

export const ChartEventContext = createContext<ChartEventContextType>({
  events: [],
  showEvents: false
});

const Explore = ({ site, project }: ExploreProps) => {
  const user = useContext(UserContext);
  const toast = useToast();
  const [searchParams] = useSearchParams();

  const { data: eventData } = useQuery<ChartEventsQuery>(GET_CHART_EVENTS, {
    variables: { siteId: site?.id }
  });

  const [chartRanges, setChartRanges] = useState<{ [chart: string]: [Date, Date] }>();
  const [deletingChartSet, setDeletingChartSet] = useState<ChartSet | null>();

  const [saveChartSetMutation] = useMutation<SaveChartSetMutation, SaveChartSetMutationVariables>(
    SAVE_CHART_SET,
    {
      refetchQueries: ['GetChartSets']
    }
  );

  const [deleteChartSetMutation] = useMutation<
    DeleteChartSetMutation,
    DeleteChartSetMutationVariables
  >(DELETE_CHART_SET, {
    refetchQueries: ['GetChartSets']
  });

  const deleteChartSet = async (id: number) => {
    try {
      const resp = await deleteChartSetMutation({ variables: { id } });
      if (resp.data.deleteChartSet) {
        toast({
          status: 'success',
          description: 'Chart Set Deleted'
        });
        setDeletingChartSet(null);
      }
    } catch {
      toast({
        status: 'error',
        description: 'Error Deleting Chart Set'
      });
    }
  };

  const { data, loading } = useQuery<GetChartSetsQuery>(GET_CHART_SETS, {
    variables: { projectId: project.id, isProjectSet: !site }
  });

  const [activeChartSetId, setActiveChartSetId] = useState<number | null>();

  useEffect(() => {
    const searchChartSetId = searchParams?.get('chartSetId');
    if (data?.chartSets && data?.chartSets?.length > 0 && searchChartSetId) {
      const cs = data.chartSets.find((cs) => cs.id === Number(searchChartSetId));
      if (!cs) return;
      setActiveChartSetId(cs.id);
      viewChartSet(cs);
    }
  }, [searchParams, data]);

  const activeChartSet = useMemo(() => {
    if (!data || !activeChartSetId) return null;

    return data?.chartSets.find((cs) => cs.id === activeChartSetId);
  }, [data, activeChartSetId]);

  // Global chart date ranges to pass to plotly
  // this allows the 'shared axis' across supported charts
  //@ts-ignore
  const exploreChartRange: [Date, Date] = useMemo(() => {
    if (!chartRanges) return;
    const dates = Object.values(chartRanges).flatMap((a) => a);

    const minMax = [min(dates), max(dates)];

    // Catch a weird edge case with singular date measurements
    if (minMax[0].toISOString() === minMax[1].toISOString()) {
      return null;
    }

    return minMax;
  }, [chartRanges]);

  const [charts, setCharts] = useState<InflatedChart[]>([]);
  const [exportData, setExportData] = useState<{ chart: string; data: any }>();
  const [chartSetModalOpen, setChartSetModalOpen] = useState<boolean>();
  const [showingChartSets, setShowingChartSets] = useState<boolean>(true);
  const [showingEvents, setShowingEvents] = useState<boolean>(false);

  const [showingChartModal, setShowingChartModal] = useState<boolean>(false);
  const [editingChart, setEditingChart] = useState<{
    chart: InflatedChart;
    index: number;
  } | null>();

  const { control, setValue } = useForm({
    defaultValues: {
      timeFragment: {
        granularity: 'day',
        dateRange: 'last 7 days until now'
      } as TimeFragment
    }
  });

  const { charts: availableCharts } = useExploreCharts({ site });

  const timeFrame = useWatch({ control, name: 'timeFragment' });

  const dataExport = () => {
    const exports = Object.entries(exportData).map(([chartName, chartData]) => {
      const headers: string[] = uniq(chartData.flatMap((datum) => Object.keys(datum)));
      const rowData = chartData.map((datum) => headers.map((header) => datum[header]));

      return { filename: `${chartName}.csv`, data: [headers, ...rowData] };
    });

    toCsvZip(exports);
  };

  const registerExportData = ({ chart, data }: { chart: string; data: any[] }) => {
    setExportData({ ...exportData, [chart]: data });
  };

  const serializeSettings = <T extends BaseChartSettings & { query?: Query }>(settings: T) => {
    const exclude = ['site', 'project'];
    // If we are on site explore, dont save site id so the set can be used at any site.
    if (site) {
      exclude.push('siteId');

      const excludeMembers = ['Explore.Site_id', 'Site.id'];
      const customFilters = settings?.query?.filters;
      // Make sure to exclude it from custom charts/query settings as well.
      if (
        customFilters?.length > 0 &&
        excludeMembers.some((m) => customFilters?.find((f: BinaryFilter) => f.member === m))
      ) {
        settings.query = {
          ...settings.query,
          filters: customFilters.filter((f: BinaryFilter) => !excludeMembers.includes(f.member))
        };
      }
    }

    return JSON.stringify({
      projectId: settings.project.id,
      siteId: site ? null : settings.site?.id,
      ...omit(settings, ...exclude)
    });
  };

  const inflateSettings = (settings: any) => ({
    ...settings,
    site: settings?.siteId ? project.sites.find((s) => s.id === settings.siteId) : site,
    project: settings?.projectId ? project : null
  });

  const saveChartSet = async (
    info: { id?: number; name: string; description: string },
    copy: boolean
  ) => {
    setChartSetModalOpen(false);
    try {
      const resp = await saveChartSetMutation({
        variables: {
          id: !copy ? info?.id : null,
          projectId: project.id,
          chartSet: {
            name: copy && info.name === activeChartSet.name ? `${info.name} - Copy` : info.name,
            description: info.description,
            charts: charts.map((c) => ({
              chartId: c.id,
              displayName: c.displayName,
              settings: serializeSettings(c.settings)
            })),
            timeRange: {
              // If the date range is custom, save each part, start and end
              startTime: isArray(timeFrame.dateRange) ? timeFrame?.dateRange[0] : null,
              endTime: isArray(timeFrame.dateRange) ? timeFrame?.dateRange[1] : null,
              granularity: timeFrame?.granularity,
              // If the date range is a string, save it under this field
              dateRange: isArray(timeFrame.dateRange) ? null : timeFrame.dateRange
            },
            isProjectSet: !site
          }
        }
      });

      if (resp.data.saveChartSet) {
        toast({
          status: 'success',
          description: 'Chart Set Saved'
        });

        setActiveChartSetId(resp.data.saveChartSet);
      }
    } catch (e: any) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error Saving Chart Set'
      });
    }
  };

  const viewChartSet = (chartSet: ChartSet) => {
    setActiveChartSetId(chartSet.id);
    setChartRanges(null);
    const dateRange: DateRange = chartSet.timeRange.dateRange
      ? chartSet.timeRange.dateRange
      : [
          format(new Date(chartSet.timeRange.startTime), 'yyyy-MM-dd'),
          format(new Date(chartSet.timeRange.endTime), 'yyyy-MM-dd')
        ];
    setValue('timeFragment', {
      granularity: chartSet.timeRange.granularity as TimeDimensionGranularity,
      dateRange
    });

    const inflatedCharts = chartSet.charts.map((c) => {
      const exploreChart = availableCharts.find((ac) => ac.id === c.chartId);
      if (!exploreChart) return;
      return {
        ...exploreChart,
        displayName: c.displayName ?? exploreChart.displayName,
        settings: inflateSettings(c.settings)
      };
    });

    setCharts(inflatedCharts);
  };

  return (
    <>
      {chartSetModalOpen && (
        <ChartSetModal
          chartSet={activeChartSet}
          onSubmit={(info, copy) => saveChartSet(info, copy)}
          isOpen={chartSetModalOpen}
          onClose={() => setChartSetModalOpen(false)}
        />
      )}
      {(showingChartModal || !!editingChart) && (
        <ChartEditorModal
          isOpen={showingChartModal || !!editingChart}
          charts={editingChart?.chart ? [] : availableCharts}
          editingChart={editingChart?.chart}
          onClose={() => {
            setEditingChart(null);
            setShowingChartModal(false);
          }}
          onSave={(chart) => {
            if (editingChart) {
              setCharts(fill(charts, chart, editingChart.index));
            } else {
              setCharts([...charts, chart]);
            }
            setShowingChartModal(false);
            setEditingChart(null);
          }}
        />
      )}
      <TabHeadline
        text="Explore site data to understand historical trends and debrief extreme ocean conditions or other events at the farm site."
        icon={<SiWpexplorer />}
      />
      <HStack alignItems="start">
        {showingChartSets && (
          <Card w="20%">
            <CardHeader textAlign="center">Chart Sets</CardHeader>
            <CardBody>
              {loading ? (
                <Skeleton w="100%" h="200px" />
              ) : data?.chartSets.length === 0 ? (
                <Notice>
                  <Text>No Chart Sets Available.</Text>
                </Notice>
              ) : (
                <VStack>
                  {data.chartSets.map((cs) => (
                    <HStack w="100%" key={cs.id}>
                      <Button
                        variant={activeChartSet?.id === cs.id ? 'solid' : 'outline'}
                        w="100%"
                        onClick={() => viewChartSet(cs)}>
                        <OverflownText>{cs.name}</OverflownText>
                      </Button>
                      {!isEmpty(cs.description) && <InfoPopover>{cs.description}</InfoPopover>}
                      {permissions.canAdminProject(user, project.id) && (
                        <IconButton
                          icon={<DeleteIcon color="red.500" />}
                          aria-label={'Delete Chart Set'}
                          onClick={() => {
                            setDeletingChartSet(cs);
                          }}
                        />
                      )}
                    </HStack>
                  ))}
                  <Button
                    whiteSpace="normal"
                    variant="solid"
                    color="blue.500"
                    w="100%"
                    onClick={() => {
                      setActiveChartSetId(null);
                      setCharts([]);
                    }}>
                    Clear
                  </Button>
                </VStack>
              )}
            </CardBody>
          </Card>
        )}
        <VStack w={showingChartSets ? '80%' : '100%'} minH="1200px">
          <Tile w="100%">
            <VStack w="100%">
              <HStack
                w="100%"
                pl="15px"
                pr="15px"
                mb="10px"
                mt="10px"
                justifyContent="space-between">
                <FormTimeFragmentPicker control={control} name="timeFragment" />

                <Button
                  onClick={() => setShowingChartModal(true)}
                  mt="50px"
                  maxW="50%"
                  w="100%"
                  colorScheme="blue"
                  leftIcon={<AddIcon mb="2px" />}>
                  Add Chart
                </Button>

                <Button
                  minW="120px"
                  mr="10px"
                  colorScheme="blue"
                  variant="outline"
                  onClick={dataExport}>
                  Export Data
                </Button>

                {permissions.canAdminProject(user, project.id) && (
                  <Button
                    minW="120px"
                    isDisabled={charts.length === 0}
                    leftIcon={<IoMdSave style={{ fontSize: '20px', marginBottom: '3px' }} />}
                    mr="10px"
                    colorScheme="blue"
                    onClick={() => setChartSetModalOpen(true)}>
                    Save
                  </Button>
                )}
              </HStack>
              {site && (
                <FormControl mt="15px" pl="15px">
                  <HStack>
                    <FormLabel p="0" pt="10px">
                      Show Events
                    </FormLabel>
                    <Switch
                      justifyContent="center"
                      id="show-events"
                      onChange={(e) => setShowingEvents(e.currentTarget.checked)}
                      isChecked={showingEvents}
                    />
                  </HStack>
                </FormControl>
              )}
            </VStack>
          </Tile>

          <Box w="100%">
            <Button
              rightIcon={showingChartSets ? <ArrowLeftIcon /> : <ArrowRightIcon />}
              onClick={() => setShowingChartSets(!showingChartSets)}
              variant="link">
              {showingChartSets ? 'Minimize' : 'Show Chart Sets'}
            </Button>
          </Box>

          <ChartEventContext.Provider
            value={{ events: eventData?.getEvents ?? [], showEvents: showingEvents }}>
            {charts.map((c, i) => (
              <HStack w="100%" key={i}>
                <Box>
                  <IconButton
                    color="blue.500"
                    icon={<EditIcon />}
                    aria-label={'Edit'}
                    onClick={() => setEditingChart({ chart: c, index: i })}
                  />
                </Box>
                {/** NOTE: Careful with className here. This is used in the screenshot function of
                chart sets to count number of charts loading / expected. **/}
                <Box w="100%" className="chartset-plot">
                  <InflatedChartRender
                    inflatedChart={c}
                    timeFrame={timeFrame}
                    chartRange={exploreChartRange}
                    site={site}
                    onDataLoaded={(resultSets) => {
                      if (c.custom) {
                        const data = resultSets.filter((rs) => rs).flatMap((rs) => rs?.rawData());
                        registerExportData({ chart: `${c.displayName} - ${i}`, data });
                      } else {
                        resultSets
                          .filter((rs) => rs)
                          .forEach((rs) => {
                            rs.query().timeDimensions?.forEach((td) => {
                              const dates = rs.rawData().map((d) => new Date(d[td.dimension]));
                              if (dates.length === 0) return;
                              const earliestDate = min(dates);
                              const latestDate = max(dates);

                              setChartRanges({
                                ...chartRanges,
                                ...{ [c.displayName]: [earliestDate, latestDate] }
                              });
                            });
                          });
                        const data = resultSets.filter((rs) => rs).flatMap((rs) => rs?.rawData());
                        registerExportData({ chart: `${c.displayName} - ${i}`, data });
                      }
                    }}
                  />
                </Box>
                <Box>
                  <IconButton
                    color="red.500"
                    icon={<DeleteIcon />}
                    aria-label={'Delete'}
                    onClick={() => {
                      setCharts(charts.filter((c, idx) => idx !== i));
                      if (exportData) {
                        delete exportData[`${c.displayName} - ${i}`];
                        setExportData(exportData);
                      }
                    }}
                  />
                </Box>
              </HStack>
            ))}
          </ChartEventContext.Provider>
        </VStack>
      </HStack>
      <ConfirmDeleteAlert
        dialogHeader={<Text>Delete Chart Set?</Text>}
        isOpen={!!deletingChartSet}
        onClose={() => setDeletingChartSet(null)}
        onVerifyDelete={() => deleteChartSet(deletingChartSet.id)}
      />
    </>
  );
};
export default Explore;
