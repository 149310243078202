import { Button, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Text } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link as ReactLink } from 'react-router-dom';

const AdminMenu = ({ projectId }: { projectId: number }) => {
  return (
    <Menu>
      <MenuButton
        color="blue.500"
        as={Button}
        variant="link"
        rightIcon={<ChevronDownIcon mb="2px" />}>
        <Text>Admin</Text>
      </MenuButton>
      <MenuList>
        <MenuGroup title="Users">
          <MenuItem as={ReactLink} to={`/project/${projectId}/admin/users`}>
            Manage
          </MenuItem>
        </MenuGroup>
        <MenuGroup title="Equipment">
          <MenuItem as={ReactLink} to={`/project/${projectId}/admin/equipment/manage`}>
            Manage
          </MenuItem>
          <MenuItem as={ReactLink} to={`/project/${projectId}/admin/equipment/assign`}>
            Assign
          </MenuItem>
        </MenuGroup>
        <MenuGroup title="Project">
          <MenuItem as={ReactLink} to={`/project/${projectId}/config/plankton`}>
            Plankton
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default AdminMenu;
