import { Center, Tab, TabList, TabPanels, Tabs, VStack } from '@chakra-ui/react';
import { permissions } from '@scoot/permissions';
import { ErrorBoundary } from '@sentry/react';
import { RenderError } from 'components/Pages/RenderError';
import { ProjectContext } from 'contexts/ProjectContext';
import { UserContext } from 'contexts/UserContext';
import { FC, useContext } from 'react';
import { BiGitCompare } from 'react-icons/bi';
import { IoMdMap } from 'react-icons/io';
import { TbBinaryTree, TbReport } from 'react-icons/tb';
import { Outlet, useLocation } from 'react-router-dom';
import { Link as ReactLink } from 'react-router-dom';

export const TabErrorBoundary: FC = ({ children }) => (
  <ErrorBoundary
    fallback={
      <Center width={'100vw'} height={'100vh'}>
        <RenderError />
      </Center>
    }
    showDialog={false}>
    {children}
  </ErrorBoundary>
);

const ProjectLayout = () => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);
  const location = useLocation();

  const tabSlugs = permissions.canAdminProject(user, project.id)
    ? ['overview', 'fish-groups', 'reports']
    : ['overview', 'reports'];

  return (
    <VStack w="100%">
      <Tabs index={tabSlugs.findIndex((ts) => location.pathname.includes(ts)) + 1 ?? 0} w="100%">
        <TabList mb="15px">
          <ReactLink to={`/project/${project.id}`}>
            <Tab>
              <IoMdMap fontSize="22px" />
              Map
            </Tab>
          </ReactLink>
          <ReactLink to={`/project/${project.id}/overview`}>
            <Tab>
              <BiGitCompare fontSize="22px" />
              Overview
            </Tab>
          </ReactLink>
          {permissions.canAdminProject(user, project.id) && (
            <ReactLink to={`/project/${project.id}/fish-groups`}>
              <Tab>
                <TbBinaryTree fontSize="22px" />
                Fish Groups
              </Tab>
            </ReactLink>
          )}
          <ReactLink to={`/project/${project.id}/reports`}>
            <Tab>
              <TbReport fontSize="22px" />
              Reports
            </Tab>
          </ReactLink>
        </TabList>

        <TabPanels>
          <TabErrorBoundary>
            <Outlet context={project} />
          </TabErrorBoundary>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default ProjectLayout;
