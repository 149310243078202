import { UserContext } from 'contexts/UserContext';
import { useContext } from 'react';
import ReportsPage from './ReportsPage';

const ReportingIndex = () => {
  const user = useContext(UserContext);
  return <ReportsPage filter={{ authorEmail: user.email }} />;
};

export default ReportingIndex;
