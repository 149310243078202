import { gql, useQuery } from '@apollo/client';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Input,
  SimpleGrid,
  Skeleton,
  Text,
  useCheckboxGroup
} from '@chakra-ui/react';
import CheckboxCard from 'components/Forms/CheckboxCard';
import { ProjectContext } from 'contexts/ProjectContext';
import { difference, isEmpty } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';
import { EditAlarmForm } from '../EditAlarmModal';

const PROJECT_SITES = gql`
  query ProjectSites($projectId: Int!) {
    sites(projectId: $projectId, order: { field: "name", order: ASC }) {
      id
      name
      sublocations
      smbId
      siteLabel
    }
  }
`;

const SelectSite = ({
  control,
  editing
}: {
  control: Control<EditAlarmForm>;
  editing: boolean;
}) => {
  const project = useContext(ProjectContext);
  const { data, loading } = useQuery(PROJECT_SITES, { variables: { projectId: project.id } });
  // const { field } = useController({ control, name: 'siteAlarms' });
  const { fields, append, remove } = useFieldArray<EditAlarmForm, 'siteAlarms'>({
    control,
    name: 'siteAlarms'
  });

  const { getCheckboxProps } = useCheckboxGroup({
    value: fields.map(({ site }) => site.id),
    onChange: (idStrings) => {
      const checkIds = idStrings.map((id) => Number(id));
      const existingSiteIds = fields.map((s) => s.site.id);

      const newIds = difference(checkIds, existingSiteIds);
      const removeIds = difference(existingSiteIds, checkIds);

      newIds.forEach((newId) =>
        append({ site: data.sites.find((s) => s.id === newId), notify: [] })
      );

      removeIds.forEach((removeId) => remove(existingSiteIds.indexOf(removeId)));
    }
  });

  const [siteFilter, setSiteFilter] = useState('');

  const sites = useMemo(() => {
    if (!isEmpty(siteFilter) && data?.sites.length > 0) {
      return data.sites.filter((s) => s.name.toLowerCase().includes(siteFilter.toLowerCase()));
    }

    return data?.sites ?? [];
  }, [data?.sites, siteFilter]);

  const selectAll = (siteLabel?: string) => {
    const sitesOfTypeId = data.sites
      .filter((site) => (siteLabel ? site.siteLabel === siteLabel : true))
      .map((s) => s.id);
    const existingSiteIds = fields.map((s) => s.site.id);

    const newIds = difference(sitesOfTypeId, existingSiteIds);
    newIds.forEach((newId) => append({ site: data.sites.find((s) => s.id === newId), notify: [] }));
  };

  return (
    <>
      <Center m="20px">
        <Text fontSize="2xl">Select Sites</Text>
      </Center>
      <Text mt="10px" mb="10px">
        Select site(s) for your alarm. If you select multiple sites, they will be created
        individually.
      </Text>
      {fields.length > 1 && (
        <Alert mb="10px" mt="10px" status="info" variant="left-accent">
          <AlertIcon mb="5px" />
          <Box>
            <Text>
              Select site(s) for your alarm. If you select multiple sites, designate recipients for
              alert emails and text messages for each site in step 3.
            </Text>
            <Text>
              Example: If you select Site 1, and Site 2 the alarm will monitor Site 1 and Site 2 as
              individual sites. Site data <i>is not</i> aggregated together in any way.
            </Text>
          </Box>
        </Alert>
      )}
      {loading && <Skeleton h="500px" />}
      <Button
        ml="5px"
        mb="20px"
        onClick={() => selectAll('Farm')}
        colorScheme="blue"
        variant="outline">
        All Farms
      </Button>
      <Button
        ml="5px"
        mb="20px"
        onClick={() => selectAll('Seed Area')}
        colorScheme="blue"
        variant="outline">
        All Seed Sites
      </Button>
      <Button
        ml="5px"
        mb="20px"
        onClick={() => selectAll('Pilot')}
        colorScheme="blue"
        variant="outline">
        All Pilot Sites
      </Button>
      <Button
        ml="5px"
        mb="20px"
        onClick={() => selectAll('ASC')}
        colorScheme="blue"
        variant="outline">
        All ASC Sites
      </Button>
      <Button
        ml="5px"
        mb="20px"
        onClick={() => selectAll('Sensor')}
        colorScheme="blue"
        variant="outline">
        All Sensor Sites
      </Button>
      <Input
        mb="15px"
        placeholder="Filter..."
        onChange={(e) => setSiteFilter(e.currentTarget.value)}
      />
      <SimpleGrid columns={3} spacing="5">
        {sites.map((s) => {
          const checkbox = getCheckboxProps({ value: s.id });
          return (
            <CheckboxCard key={s.id} {...checkbox}>
              <Text display="inline-block" data-cypress={`select-site-${s.id}`}>
                {s.name}
              </Text>
            </CheckboxCard>
          );
        })}
      </SimpleGrid>
      {editing && (
        <Alert mt="10px" status="warning" variant="left-accent">
          <AlertIcon mb="5px" />
          When removing an existing site from an alarm all alarm history for that site will be
          deleted.
        </Alert>
      )}
    </>
  );
};

export default SelectSite;
