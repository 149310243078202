import { HStack, Image, Text } from '@chakra-ui/react';
import Select from 'react-select';

type PlanktonSelectProps = {
  value: { key: string; name: string };
  species: { key: string; name: string }[];
  onChange: (species: { key: string; name: string }) => void;
  cellStyle?: boolean;
};

const PlanktonEntryGridSelect = ({
  value,
  species,
  onChange,
  cellStyle = true
}: PlanktonSelectProps) => {
  const planktonOptions = species.map((s) => ({
    value: s,
    label: (
      <HStack alignItems="center">
        <Image fallback={<></>} h="40px" src={`/plankton/${s.key}.png`} />
        <Text>{s.name}</Text>
      </HStack>
    )
  }));

  return (
    <Select
      value={planktonOptions.find((po) => po.value.key === value.key)}
      styles={
        cellStyle
          ? {
              container: (baseStyles) => ({ ...baseStyles, lineHeight: 'initial', height: '100%' }),
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              control: (_baseStyles) => ({ backgroundColor: 'none', paddingTop: '6px' }),
              input: (baseStyles) => ({ ...baseStyles, color: 'black', margin: 0, paddingTop: 0 }),
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              indicatorsContainer: (_base) => ({ display: 'none' }),
              menuPortal: (baseStyles) => ({
                ...baseStyles,
                zIndex: 999999
              }),
              menu: (baseStyles) => ({
                ...baseStyles,
                position: 'absolute',
                top: 0,
                left: 0,
                color: 'black',
                width: '400px'
              }),
              menuList: (baseStyles) => ({ ...baseStyles, margin: 0 })
            }
          : {
              control: (baseStyles) => ({
                ...baseStyles,
                width: '100%',
                border: '1px solid var(--chakra-colors-chakra-border-color)'
              })
            }
      }
      options={planktonOptions}
      onChange={(option) => onChange(option.value)}
    />
  );
};

export default PlanktonEntryGridSelect;
