import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Center, Heading, Image, Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import { statusCodeMap } from 'components/Charts/SIWI/SIWIShared';
import InfoTable from 'components/Tables/InfoTable';
import { Fragment } from 'react';
import { FAQPage } from './FAQTypes';

const errorStatusCodes: { title: string; description: string }[] = [
  {
    title: statusCodeMap.input_missing_hydro.readable,
    description:
      'Temperature or oxygen data from the recent 24 hour period is missing. SIWI does not try to extrapolate these data streams.'
  },
  {
    title: statusCodeMap.qc_hydro_failure.readable,
    description:
      'The quality control routine removed temperature or oxygen data from the recent 24 hour period.'
  },
  {
    title: statusCodeMap.cannot_fill_intermediate_data_because_no_hist_ind_data.readable,
    description:
      'Non-hydrography data from the recent 24 hour period is missing and could not be extrapolated because the historical data table is empty.'
  },
  {
    title:
      statusCodeMap['cannot_fill_intermediate_data_because_hist_ind_data_has_been_filled'][
        'readable'
      ],
    description:
      'Non-hydrography data has been missing for multiple days in a row and could not be extrapolated, because there is a limit to how far out SIWI will fill in missing data.'
  },
  {
    title: statusCodeMap.cannot_calculate_indicator_score.readable,
    description: 'Unable to calculate at least one indicator score.'
  }
];

const warningStatusCodes: { title: string; description: string }[] = [
  {
    title: statusCodeMap.indicator_data_missing.readable,
    description:
      'Non-hydrography data from the recent 24 hour period is missing and has been extrapolated using prior days’ data. You may check the Latest Stress Index hover tool to determine which specific indicator value is missing for the index.'
  },
  {
    title: statusCodeMap.cannot_calculate_longterm_ko.readable,
    description:
      'Cannot confirm whether a KO has occurred from prolonged, potentially lethal conditions, because SIWI could not be calculated for the last several days.'
  },
  {
    title: statusCodeMap.using_manual_barge_data.readable,
    description:
      'High resolution pen data was not available for the last 24 hours. Manual barge data was used as a proxy for hydrography instead.'
  },
  {
    title: statusCodeMap.using_hi_res_barge_data.readable,
    description:
      'High resolution pen data was not available for the last 24 hours. High resolution barge data was used as a proxy for hydrography instead.'
  }
];

const glossary = [
  {
    term: 'Stress',
    definition:
      'SIWI is ultimately a measure of stress on your fish (the inverse of fish welfare). We consider “stress” to be anything that is negatively affecting the overall welfare of your fish. For some indicators, “stress” may be actively reducing welfare, for example a low oxygen saturation, while other indicators may be reflective of reduced welfare, for example reduced appetite. This broadened definition of stress allows us to take a more holistic measure of fish welfare.'
  },
  {
    term: 'SIWI stress total',
    definition:
      'This is the sum of your indicator contribution values. It represents the overall level of stress experienced at your site each day.'
  },
  {
    term: 'Stress indicator',
    definition: (
      <>
        <Text>
          Indicators are the 10 components for assessing overall fish stress, each of which has four
          different values defined below: indicator contribution, indicator score, indicator value,
          and weight factor. There are two types of indicators:
        </Text>
        <UnorderedList>
          <ListItem>
            <Text as="b">Indicators that impact stress:&nbsp;</Text>
            <Text display="inline">
              Temperature, Oxygen, Salinity, Lice, Plankton, Stocking Density, and Disturbances
              indicators all have an effect on fish welfare.
            </Text>
          </ListItem>
          <ListItem>
            <Text as="b">Indicators that reflect underlying stress:&nbsp;</Text>
            <Text display="inline">
              Mortality and Appetite. Mortality and Appetite assess the fish relative to what is
              expected. Unusual activity without an explanation, such as low appetite, can indicate
              other underlying health issues and potentially poor welfare.
            </Text>
          </ListItem>
        </UnorderedList>
      </>
    )
  },
  {
    term: 'Indicator score',
    definition:
      'Each indicator has a unique score. These values will always be between 0 and 100, where 0 is no stress (indicating optimal conditions) and 100 is the highest possible stress score (indicating potentially lethal conditions). SIWI can not score health above lethal conditions since at that point significant mortality is observed.'
  },
  {
    term: 'Indicator contribution',
    definition:
      'Each indicator has a unique contribution value, which is the contribution of the indicator to total stress. Contribution values help you decide which stressor to prioritize. The sum of your indicator contributions is equal to the SIWI stress total.'
  },
  {
    term: 'Indicator value',
    definition: 'The value of the data inputs used directly to calculate each indicator score.'
  },
  {
    term: 'Category score',
    definition:
      'SIWI indicators can be summarized into three categories: Environmental, Operations, and Mortality. Like the indicator score, each category has a unique score ranging from 0 and 100.'
  },
  {
    term: 'Category contribution',
    definition:
      'SIWI indicators can be summarized into three categories: Environmental, Operations, and Mortality. Each category has a total contribution which is the sum of the indicator contributions in that category. The sum of category contributions is equal to the SIWI stress total.'
  },
  {
    term: 'Optimal',
    definition:
      'Each indicator has an optimal range (associated with a score of 0). This reflects conditions that promote the most efficient growth, reduced susceptibility to disease, and environmental conditions that salmon show preference towards.'
  },
  {
    term: 'Knockout (KO)',
    definition:
      'SIWI is designed to highlight sublethal stress that may negatively affect your cohorts’ performance. A knockout level score reflects a lethal condition. On days with knockout level scores, increased mortality should be a serious concern.'
  },
  {
    term: 'Weight factor',
    definition:
      'A weight factor is assigned to each indicator to calculate the indicator’s contribution to fish stress. The higher the weight, the larger the impact. The value is determined by tallying the number of physiological needs that this indicator is linked to as well as the magnitude of impact for each. This weight is reflected as the width of each indicator wedge on the Latest Stress Index donut plot.'
  }
];

const siwiIndicatorsDefs = [
  { symbol: 's', indicator: 'siwi' },
  { symbol: 'm', indicator: 'mortality' },
  { symbol: 'sal', indicator: 'salinity' },
  { symbol: 'o', indicator: 'oxygen' },
  { symbol: 'a', indicator: 'appetite' },
  { symbol: 'l', indicator: 'lice' },
  { symbol: 'd', indicator: 'disturbance' },
  { symbol: 'pT', indicator: 'plankton Toxic' },
  { symbol: 'pM', indicator: 'plankton Mechanical' },
  { symbol: 't', indicator: 'temperature' },
  { symbol: 'sto', indicator: 'stocking' }
];

const siwiParametersDefs = [
  { sybmol: 'T', definition: " 'total'" },
  { sybmol: 'E', definition: " 'Environmental'" },
  { sybmol: 'F', definition: " 'Fish'" },
  { sybmol: 'I', definition: " 'Individual'" },
  { sybmol: 'V', definition: " 'Value' - The raw value of the indicator (e.g. 12degC)" },
  {
    sybmol: 'W',
    definition:
      " 'Weight' - The indicators weighting (weight * score = contribution to overall score)"
  },
  { sybmol: 'S', definition: " 'Score' - The indicators siwi score (0-100)" },
  { sybmol: 'M', definition: " 'Missing' - If the indicator is missing and has been interpolated" },
  { sybmol: 'KO', definition: " 'KO' - If the indicator is in a KO state" },
  { sybmol: 'U', definition: " 'Unit' - The unit of the indicator (e.g. lice per cm2)" }
];

type Indicator = {
  name: string;
  category: 'Environmental' | 'Operations' | 'Mortality';
  weight: string;
  description: string;
  scores: {
    score: number | string;
    range: string;
    description: string;
  }[];
  images?: {
    description: string | Element;
    path: string;
  }[];
};

const indicators: Indicator[] = [
  {
    name: 'Oxygen',
    category: 'Environmental',
    weight: '17',
    description:
      'Oxygen percent saturation and temperature determine the amount of oxygen available for the fish to take in through their gills. Oxygen availability is an important stress indicator given the impact of oxygen on the fishes’ ability to breathe normally. This indicator takes temperature into consideration as well to account for the impact of temperature on oxygen demand. The oxygen % values shown for each level below represent the values associated with the temperature range that salmon are most commonly farmed in (9-15 C). However, this indicator also accounts for the fact that fish are able to tolerate lower oxygen % saturations in cooler temperatures. For example, an oxygen saturation of 65% would get an indicator score of 50, if the temperature was between 9 and 15 C. The same oxygen % saturation would get an indicator score of 100, if temperature was over 15 C. See the SIWI FAQ Appendix section for a breakdown of oxygen % saturation and temperature ranges for each score level.',
    scores: [
      {
        score: 0,
        range: '>80%, all temperatures',
        description: 'Salmon exhibit optimal growth and no welfare problems.'
      },
      {
        score: 50,
        range: '60-80%',
        description: 'Salmon may experience reduced growth compared to optimal conditions.'
      },
      {
        score: 100,
        range: '40-60%',
        description:
          'Salmon avoid this range. Increased mortality, poor growth, and illness may be observed.'
      },
      {
        score: 'KO',
        range: '<40%',
        description:
          'Lethal conditions. Significant increase in mortality, poor growth, and illness is observed.'
      }
    ],
    images: [
      {
        description:
          'Breakdown of oxygen indicator stress scores depending on oxygen saturation and temperature:',
        path: '/siwi_oxygen.png'
      }
    ]
  },
  {
    name: 'Plankton',
    category: 'Environmental',
    weight: '17 - Mechanical, 15 - Toxic',
    description:
      'Plankton can lower dissolved oxygen in the water, cause mechanical harm by lodging in the gills, and can be toxic to salmon. These impacts are linked to both physical and behavioral fish welfare. Plankton is split up into two indicators due to the slightly different impacts that mechanical plankton and toxic plankton have on salmon. While both have the same number of levels, the mechanical plankton indicator is weighted slightly more than toxic plankton indicator. It may take a couple of days for impacts from potentially lethal concentrations of mechanical plankton to show up in the indicator score. Co-occurrence of multiple harmful plankton concentrations is particularly lethal for salmon (see “KO” criteria below).',
    scores: [
      {
        score: '0',
        range: 'safe',
        description: 'Safe as determined by company SOP'
      },
      {
        score: '50',
        range: 'cautionary',
        description: 'Cautionary as determined by company SOP'
      },
      {
        score: '100',
        range: 'potentially lethal',
        description: 'Potentially lethal as determined by company SOP'
      },
      {
        score: 'KO',
        range: 'Mechanical - potentially lethal & co-occurrence',
        description:
          'Co-occurrence of a mechanical plankton species with potentially lethal concentrations and a different mechanical plankton species with at least a cautionary concentration for 2 days in a row.'
      },
      {
        score: 'KO',
        range: 'Toxic - potentially lethal & co-occurrence',
        description:
          'Co-occurrence of a toxic plankton species with potentially lethal concentrations and a different toxic plankton species with at least a cautionary concentration.'
      }
    ]
  },
  {
    name: 'Temperature',
    category: 'Environmental',
    weight: '16',
    description:
      "Temperature along with oxygen affects the fishes' capacity to withstand stressors. Specifically, temperature has been linked to the ability for salmon to breathe normally and regulate body temperatures. The following temperature levels represent ranges in which salmon show varying levels of avoidance/preference and growth.",
    scores: [
      {
        score: 0,
        range: '10-15 C',
        description: 'Optimal growth conditions. Salmon show preference for these conditions.  '
      },
      {
        score: 25,
        range: '7-10 C',
        description:
          'Though this range is not harmful to salmon, conditions are less than optimal for growth. '
      },
      { score: 50, range: '16-17 C', description: 'Salmon are susceptible to harm.' },
      {
        score: 75,
        range: '3-6 C',
        description:
          'Salmon are susceptible to harm, and have slightly less preference for this range than the 16-17 C range.'
      },
      {
        score: 100,
        range: '<=2 or >= 18 C',
        description:
          'Salmon avoid this range. Negative performance, illness, or reduced survival is observed.'
      },
      {
        score: 'KO',
        range: '<=2 or >= 18 C, prolonged',
        description: 'Lethal conditions - the conditions above have persisted for at least 3 days.'
      }
    ]
  },
  {
    name: 'Lice',
    category: 'Environmental',
    weight: '11',
    description:
      'Salmon experience elevated stress when exposed to sea lice infestation even before the lice are in a stage where they are actively feeding on the salmon. However, increased mortality is usually observed in the pre-adult stages and high lice presence is linked to increased stress.',
    scores: [
      { score: 0, range: '<= 0.00013 lice/cm2', description: 'Safe conditions' },
      { score: 33, range: '0.00013 - 0.05 lice/cm2', description: 'Less optimal conditions' },
      {
        score: 66,
        range: '0.05 - 0.08 lice/cm2',
        description:
          'Poor conditions - fish begin to experience compromised welfare, increased mortality, and/or reduced growth.'
      },
      {
        score: 100,
        range: '0.08 - 0.12 lice/cm2',
        description:
          'Very poor conditions - fish begin to experience compromised welfare, increased mortality, and/or reduced growth.'
      },
      { score: 'KO', range: '>= 0.12 lice/cm2', description: 'Lethal conditions' }
    ],
    images: [
      {
        //@ts-ignore
        description: (
          <>
            Example of a lice indicator score of 33 out of 100. The Atlantic salmon sampled below
            weighs 2.8 kg and has a total harmful lice count of 54, which results in 0.03 lice/cm2
            (Photo:&nbsp;
            <Link href="https://www.nina.no/archive/nina/pppbasepdf/temahefte/039.pdf" isExternal>
              Sea Lice Working Group Report <ExternalLinkIcon mx="2px" />
            </Link>
            ):
          </>
        ),
        path: '/siwi_lice.png'
      }
    ]
  },
  {
    name: 'Salinity',
    category: 'Environmental',
    weight: '3',
    description:
      'While salinity has limited welfare effects on adult salmon, juvenile fish may be more vulnerable to stress in higher salinity conditions and their physiological health may be compromised.',
    scores: [
      { score: 0, range: 'access to brackish water', description: 'Optimal growth conditions.' },
      {
        score: 50,
        range: 'adult fish with no access to brackish water',
        description: 'Average growth conditions.'
      },
      {
        score: 100,
        range: 'small or compromised fish with no access to brackish water',
        description: 'Poor growth and increased mortality.'
      }
    ]
  },
  {
    name: 'Disturbance',
    category: 'Operations',
    weight: '11',
    description:
      'The disturbance indicator quantifies the degree of human-induced disturbances, from activity around the pens, to more severe disturbances such as crowding the fish or applying bath treatments. The disturbance indicator for a site represents the most severe treatment received by any pen at that site. Severe disturbances are linked to increased vulnerability of salmon.',
    scores: [
      { score: 0, range: 'None', description: 'No human-induced disturbances.' },
      { score: 33, range: 'Mild Activity & Handling', description: 'Mild impact from activities.' },
      {
        score: 66,
        range: 'Crowding',
        description:
          "The fishes' oxygen levels may drop rapidly (not critical), behavior may change, appetite may be reduced for several days."
      },
      {
        score: 100,
        range: 'Pumping',
        description:
          'Increased mortality, poor growth, abnormal behavior, and/or increased stress occur as a result of activities. '
      }
    ]
  },
  {
    name: 'Appetite',
    category: 'Operations',
    weight: '11',
    description:
      'The appetite indicator assesses the current appetite of the fish (their willingness to eat) relative to what is expected for this cohort weight and temperature. Loss of appetite is an important indicator of general health or ability to take in nutrients.',
    scores: [
      {
        score: 0,
        range: 'Good',
        description: 'Indication of optimal growth and overall positive health.'
      },
      { score: 50, range: 'Average', description: 'Slightly less than optimal growth.' },
      { score: 100, range: 'Poor', description: 'Indication of poor growth and illness.' }
    ]
  },
  {
    name: 'Stocking Density',
    category: 'Operations',
    weight: '8',
    description:
      'The stocking density indicator is a measure of the total biomass divided by the volume of the sea cage. Regional regulators often set ceilings for stocking density to maintain a standard of fish welfare in the pens. Overcrowding of fish has been associated with welfare impacts on the fishes’ ability to rest, control buoyancy, and avoid disease spread. While fish can congregate at extreme densities, high stocking densities limit the fish’s ability to move freely and lead to decreased oxygen availability, both of which may impact growth, feeding, health, and stress.',
    scores: [
      {
        score: 0,
        range: '<22 kg/m3',
        description:
          'Low stocking densities, fish exhibit normal or optimal behavior and are able to move freely within the water column'
      },
      {
        score: 33,
        range: '22-26 kg/m3',
        description:
          "Slightly less than optimal. Densities limit the fishes' ability to find optimal conditions in the water column."
      },
      {
        score: 66,
        range: '26-32 kg/m3',
        description: 'Stocking densities start to impact growth, health, stress, and feeding.'
      },
      {
        score: 100,
        range: '>32 kg/m3',
        description: 'High stocking densities. Significant impact on growth, health, and stress.'
      }
    ]
  },
  {
    name: 'Mortality',
    category: 'Mortality',
    weight: '21',
    description:
      'The mortality stress indicator compares site mortality to the expected regional average. A relatively high mortality score may be indicative of poor site performance, disease, suboptimal environmental conditions, or physical harm. A relatively low mortality compared to the expected regional average generally indicates relatively good site performance. The regional benchmark is modeled from industry-wide available data on typical mortality ranges at each stage of your cohort’s life in sea water. Thus, the percent daily mortality range associated with each score below will vary depending on the age of your cohort.',
    scores: [
      {
        score: 0,
        range: '< 10th percentile',
        description: 'Site performance is significantly better than the regional average.'
      },
      {
        score: 25,
        range: '10th-40th percentile',
        description: 'Site performance is better  than the regional average.'
      },
      {
        score: 50,
        range: '40th-60th percentile',
        description: 'Site performance falls in the regional average.'
      },
      {
        score: 75,
        range: '60-90th percentile',
        description: 'Site performance is worse than the regional average.'
      },
      {
        score: 0,
        range: '>90th percentile',
        description: 'Site performance is significantly worse than the regional average.'
      },
      {
        score: 'KO',
        range: '>90th percentile, prolonged',
        description:
          'Site performance is significantly worse than the regional average for 5 days in a row or longer.'
      }
    ]
  }
];

const siwiInterpretation: {
  title: string;
  imageSrc: string;
  description: string;
}[] = [
  {
    title: 'How to interpret your Latest Stress Index',
    imageSrc: '/siwi_daily.jpg',
    description:
      'This donut plot visualizes your daily SIWI, covering a recent 24-hour period. The number in the center is your SIWI stress total (25 out of 100). The wedges around the circle represent the indicator scores that make up the total index. Each section is color-coded (green is good, red is bad) and given an individual indicator score between 0 (optimal conditions) and 100 (potentially lethal conditions) based on the most recent data. The width of each section remains constant and represents the relative contribution of each variable to overall fish health. The color of each section updates as the conditions at the farm site improve or degrade.\n\nIndicator scores should be considered independent of each other while the SIWI stress total combines the impact of all indicators to provide an overall assessment of fish welfare. So for example, Stocking only contributes a small amount to your overall health (represented by its small section of the pie) and on this day is in the ideal range (represented by its dark green color). Mechanical Plankton is much more impactful and here is in its worst case range (represented by the red color). Beneath the indicator score in the mouse hover-over tooltip, you will see the indicator value used to calculate that score. Indicator values are often aggregated or transformed from raw input data. Occasionally, data from an indicator is missing or falls outside of reasonable bounds. In these cases, the indicator value is interpolated from previous data and it will be flagged as missing.'
  },
  {
    title: 'How to interpret Weekly Stress Changes',
    imageSrc: '/siwi_arrow.jpg',
    description:
      'This chart displays the average daily change in each indicator and total SIWI over the last 7 days. A red arrow signifies an increase in average stress over the past 7 days, a green arrow signifies a decrease, and a circle signifies no change. Toggle between indicator scores and indicator contributions to compare indicators relative to themselves or relative to total stress, respectively. Indicator contributions (weighted) add up to the SIWI stress total for that time period.'
  },
  {
    title: 'How to interpret your Daily Stress Changes',
    imageSrc: '/siwi_bar.png',
    description:
      'This chart displays your daily SIWI stress over the past 14 days, broken down by indicator. The height of each stacked bar is the contribution of stress from each indicator to your site SIWI stress. The total height of the bar is equal to your daily SIWI stress total. Toggle “Categories” to view the bar chart summarized by Environment, Operations and Mortality indicators.\n\nA “KO” event is shown by the hatch pattern and the label above the bar. See below for the definition of Knockout events.\n\nThe symbols underneath the chart show the status code for that day’s SIWI model run. A green circle signifies that everything ran fine, a yellow triangle indicates the run completed but with caveats, and a red cross indicates the run could not be completed. Hovering over the symbol will show the status code which can be looked up in the Status Code section of this FAQ.'
  },
  {
    title: 'How to interpret your historical Cohort Comparison chart',
    imageSrc: '/siwi_cohort.jpg',
    description:
      "This chart shows the trend in SIWI stress total over the current cohorts' entire time at sea. The default view represents SIWI as a rolling 30-day average. You can compare how your current cohort has been doing for different periods of the production cycle relative to past cohort performance for the same site. Each cohort is based on our best estimate of stocking day for this site. To see the associated calendar day for each data point, use the tooltip to hover over the graph."
  },
  {
    title: 'How to interpret your historical Daily Stress Distribution chart',
    imageSrc: '/siwi_hist.jpg',
    description:
      "This chart shows the latest Daily Stress Index for this site (vertical dashed line) relative to current and prior cohorts' Daily Stress Index values. You can use this chart to  identify the most common SIWI scores and see if  the current cohort is doing better or worse than normal."
  },
  {
    title: 'Knockout events',
    imageSrc: '/siwi_ko.png',
    description:
      'A knock out event refers to a lethal stress event where the condition of any one indicator (temperature, oxygen, mortality, lice, toxic or mechanical plankton) is so poor that the SIWI stress total automatically becomes 100, regardless of whether other indicator scores reflect good welfare. Indicator scores that fall in the knock out range are also typically associated with extreme mortality. See the above chart for an example of how a knockout event is shown.'
  }
];

const dataUsage: {
  title: string;
  description: string;
}[] = [
  {
    title: 'SIWI Scope',
    description:
      'SIWI is calculated at the site level. Where possible, we use data from inside the pens, otherwise we use data from the barge. Data is aggregated to site level in different ways for each indicator. Hydrography and biology data streams are averaged for all pens and throughout the 24 hour rolling window. The mortality and lice counts are summed throughout that window. Plankton is averaged for each depth for each species and then the max value at any depth is used as the value for that species. For disturbances, we use the most severe disturbance at any given pen as the site level indicator score. Keep in mind that each SIWI stress total is calculated independently of any prior SIWI stress total and is limited to reflect your site’s overall stress based on direct, high-level impacts to your fish captured in available data streams. SIWI is unable to account for indirect impacts of treatments or mitigation, e.g. vaccines increasing the fishes’ inherent resilience to stressors.'
  },
  {
    title: 'How often is SIWI run and what data is incorporated?',
    description:
      'SIWI is run every 12 hours. A 24 hour rolling window is applied to the data, and any data inside that window will count towards the SIWI stress total. Any data recorded after the end date/time will not be incorporated until the next time the model is run. Likewise, any data that has a date/time during the 24 hour window, but was input at a later time, will not count towards the SIWI stress total. SIWI is currently not re-run to account for changes or updates in past data streams.'
  },
  {
    title: 'Data QC',
    description:
      'Each raw data stream used to calculate a SIWI stress total undergoes a unique quality control routine. Most of these routines consist of applying gross limits to the data to remove physically impossible values. For example, removing any mortality percent values that are greater than 100%. The QC routine for the hydrography data streams (temperature, salinity, and oxygen) is the most complicated and applies a gross limits test, a region-specific climatology test, and a rate of change test to remove any bad values. Because of these routines, data used to calculate a SIWI stress total may not exactly match the raw data that appears elsewhere on SeaState.'
  },
  {
    title: 'What happens if there is missing data?',
    description:
      'Data needs to be available for certain indicator data streams to calculate a SIWI stress total. Because temperature and oxygen make up such a large proportion of the SIWI stress total, if a site is lacking either temperature or oxygen data within the last 24 hours, SIWI will not be calculated. If any of the salinity, mortality, stocking density, or appetite indicators are missing data within this 24-hour window, the model will estimate the value from the last available farm data, if this data was collected recently within a reasonable timeframe. However, if there is no recent farm data for these data streams, then a SIWI stress total will not be calculated. The disturbance, lice, and plankton data streams are more likely to have episodic data collection, so having recent farm data for these indicators is not required to calculate a SIWI stress total. If no farm data is available for these indicators, we estimate  a value of 0. You can see whether an indicator was calculated using farm data or estimated values by hovering over the indicators in the Latest Daily Stress Index and Daily Stress Progression charts.'
  }
];

export const SIWIFaq: FAQPage = {
  name: 'SIWI',
  sections: [
    {
      title: 'What is SIWI?',
      body: (
        <>
          <Text>
            The Scoot Integrated Welfare Index (SIWI) synthesizes your environmental, biological,
            and operational data into a simplified, daily index of overall stress at your farm
            sites. SIWI is a single point of reference from which teams can make more informed
            decisions around resource distribution and treatment timing to minimize stress and loss
            in growth. The SIWI model is based on peer-reviewed research and feedback from salmon
            operators, and is driven by the data you are already collecting as well as your Standard
            Operating Procedures (SOP) where appropriate.
          </Text>
          <Text mt="5">
            SIWI can be represented as either a health total or a stress total (the direct inverse).
            Currently, we display SIWI stress total on SeaState to draw your attention to the likely
            sources of stress on any given day, where values range from 0 (optimal conditions) to
            100 (potentially lethal conditions).
          </Text>
          <Text mt="5">See the Glossary of Terms for an overview of key SIWI components.</Text>
        </>
      )
    },
    {
      title: 'Stress Indicator Overview',
      body: (
        <>
          <Text>
            Each indicator has four values associated with it: raw data value, indicator
            contribution, weight factor, and the indicator index/contribution (see Glossary for
            definitions)
          </Text>
          {indicators.map((ind, idx) => (
            <Fragment key={idx}>
              <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
                {ind.category} - {ind.name} (Weight Factor: {ind.weight})
              </Heading>
              <Text mb="1rem">{ind.description}</Text>
              <Text as="u">Indicator Scores:</Text>
              <InfoTable headers={['score', 'value', 'description']} data={ind.scores} />
              {ind.images
                ? ind.images.flatMap((img, i) => (
                    <Fragment key={i}>
                      <Text mt="1rem">{img.description}</Text>
                      <Center>
                        <Image src={img.path}></Image>
                      </Center>
                    </Fragment>
                  ))
                : []}
            </Fragment>
          ))}
        </>
      )
    },
    {
      title: 'How do I interpret SIWI?',
      body: (
        <>
          {siwiInterpretation.flatMap((x, i) => (
            <Fragment key={i}>
              <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
                {x.title}
              </Heading>
              <Center>
                <Image src={x.imageSrc} h={x.imageSrc ? '400px' : '0px'}></Image>
              </Center>
              <Text mb="1rem" whiteSpace="pre-line">
                {x.description}
              </Text>
            </Fragment>
          ))}
        </>
      )
    },
    {
      title: 'How do I use SIWI?',
      body: (
        <>
          <Text>
            SIWI is a qualitative indicator of overall fish stress, calculated based on available
            farm data and peer-reviewed research and feedback from salmon operators. The SIWI stress
            total and indicator values are intended to provide operators with a real time assessment
            of overall fish stress and the most likely stressors impacting fish welfare. Indicator
            values are included as an aid to operators&apos; intuition of their site performance.
          </Text>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            How should I benchmark my site&apos;s score?
          </Heading>
          <Text>
            Because SIWI is a standardized metric, it is most valuable when making relative
            comparisons. To best assess how your site is currently doing, you will also want to
            consider how your site is usually doing. Over time, we expect that you may notice that
            sites in different geographic areas may have different indicators that tend to have
            higher stress scores. These trends will help with establishing different baselines for
            what a normal or acceptable level of stress for that site should be, and what the
            typical SIWI range for that site is. For sites that are in the same region and
            experiencing similar environmental conditions, SIWI offers a standardized way to compare
            site performance and tease out how operational decisions might be affecting overall site
            welfare. You can also reference the historical line plot of the total SIWI stress total
            to compare the recent site performance to past (average) performance at that site or
            region. Making these temporal and geographic comparisons is the best way to determine
            the state of overall health of your site.
          </Text>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            What should I take away from my SIWI total?
          </Heading>
          <Text>
            SIWI is a starting point for assessing your overall site health to help you more
            efficiently decide where to focus your efforts given how much nuance there is in
            determining fish welfare. If multiple stressors are affecting a site, SIWI can help
            quickly identify which to prioritize for your site by showing the relative contribution
            of each indicator to fish stress, the recent trend in the indicator contribution, and
            the baseline conditions at that site. SIWI can also help inform prioritization at the
            company scale, by enabling apples to apples comparisons and highlighting the most
            stressed out sites and regions, as well as the primary stress indicators for each.
          </Text>
        </>
      )
    },
    {
      title: 'What data is being used in SIWI?',
      body: (
        <>
          {dataUsage.flatMap((x, i) => (
            <Fragment key={i}>
              <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
                {x.title}
              </Heading>
              <Text>{x.description}</Text>
            </Fragment>
          ))}
        </>
      )
    },
    {
      title: 'Status Codes',
      body: (
        <>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            Why don&apos;t I have a SIWI stress total? Why is my status code red?
          </Heading>
          <Text mb="5">
            If you are missing a SIWI stress total, it is likely due to bad or missing input data.
            Find your status code below to determine the specific cause. If you don&apos;t know your
            status code, you can find it by hovering your mouse over the status code symbol on the
            Daily Stress Changes figure.
          </Text>
          <InfoTable headers={['Status Code', 'Description']} data={errorStatusCodes}></InfoTable>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            Why is my status code yellow?
          </Heading>
          <Text mb="5">
            If the status code is yellow, it is because we were able to calculate a SIWI value but
            the result is not as accurate as it could have been, most likely due to missing data.
            Find your status code below to see the caveat.
          </Text>
          <InfoTable headers={['Status Code', 'Description']} data={warningStatusCodes}></InfoTable>
        </>
      )
    },
    {
      title: 'Glossary',
      body: (
        <InfoTable
          headers={['Term', 'Definition']}
          data={glossary}
          wordBreak={'normal'}></InfoTable>
      )
    },
    {
      title: 'API Reference',
      body: (
        <>
          <Heading as="h4" size="md" textAlign="center" mb="1rem" mt="2rem">
            The following mappings are required to interpret SIWI data delivered through the Scoot
            API, where data labels use truncated codes.
          </Heading>
          <Text mb="5">
            The API returns columns with the prefix TessSiwi, which can be ignored, and a parameter
            code. The parameter code is in camelCase, with a first part that begins lower case and
            second part that begins upper case.
          </Text>
          <Text mb="5">The first part of the camel cased word is the indicator.</Text>
          <InfoTable
            headers={['API Name: lowercase', 'SIWI Indicator']}
            data={siwiIndicatorsDefs}
            wordBreak={'normal'}></InfoTable>
          <Text mb="5"></Text>
          <Text mb="5">The second part of the phrase is the parameter.</Text>
          <InfoTable
            headers={['API Name: uppercase', 'Parameter']}
            data={siwiParametersDefs}
            wordBreak={'normal'}></InfoTable>
        </>
      )
    }
  ]
};
