import { gql, useMutation, useQuery } from '@apollo/client';
import { ArrowLeftIcon, CloseIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react';
import { useEditor } from '@tiptap/react';
import FancyEditor, { DEFAULT_EXTENSIONS } from 'components/Forms/FancyEditor';
import { Notice } from 'components/Notice';
import { ReportingViewProvider } from 'components/Reporting/ReportingViewContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { QueryContextProvider } from 'contexts/QueryContext';
import { UserContext } from 'contexts/UserContext';
import { format } from 'date-fns';

import { GetProjectReportViewQuery, ShareProjectReportMutation } from 'graphql/generated';
import { isEmpty } from 'lodash';

import { useContext, useEffect, useState } from 'react';
import { BiShare } from 'react-icons/bi';
import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom';

const UPDATE_PROJECT_REPORT_SHARE = gql`
  mutation ShareProjectReport($projectReportId: Int!, $share: Boolean!) {
    updateProjectReport(projectReportId: $projectReportId, projectReport: { share: $share }) {
      id
    }
  }
`;

const GET_PROJECT_REPORT = gql`
  query GetProjectReportView($reportId: Int!) {
    projectReport(reportId: $reportId) {
      id
      type
      name
      description
      content
      created
      url
      published
      parentProjectReportId
      shared
      createdBy {
        oryId
        name
        email
      }
    }
  }
`;

const DELETE_PROJECT_REPORT = gql`
  mutation DeleteProjectReport($projectReportId: Int!) {
    deleteProjectReport(projectReportId: $projectReportId)
  }
`;

const Info = ({ name, value }: { name: string; value: string }) => {
  return (
    <VStack spacing="0" alignItems="start">
      <Text fontSize="sm" as="b" color="gray.500">
        {name}
      </Text>
      <Text>{value}</Text>
    </VStack>
  );
};

const ReportsView = () => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const { reportId } = useParams();

  const { data, loading, error } = useQuery<GetProjectReportViewQuery>(GET_PROJECT_REPORT, {
    variables: { reportId: Number(reportId) }
  });

  const [shareProjectReport] = useMutation<ShareProjectReportMutation>(
    UPDATE_PROJECT_REPORT_SHARE,
    {
      refetchQueries: ['GetProjectReport']
    }
  );

  const [deleteProjectReport] = useMutation(DELETE_PROJECT_REPORT, {
    refetchQueries: ['GetProjectReports']
  });

  const editor = useEditor({
    extensions: DEFAULT_EXTENSIONS,
    content: data?.projectReport?.content,
    editable: false,
    autofocus: false
  });

  useEffect(() => {
    if (data?.projectReport && !loading) {
      editor.commands.setContent(data.projectReport.content);
    }
  }, [data, loading]);

  const [isVerifyingDelete, setDeleteVerification] = useState(false);
  const [isVerifyingShare, setIsVerifyingShare] = useState(false);

  const verifyDelete = async () => {
    if (!isVerifyingDelete) {
      setDeleteVerification(true);
    } else {
      await deleteProjectReport({
        variables: { projectReportId: data?.projectReport?.id },
        refetchQueries: ['GetProjectReports']
      });
      navigate(`/project/${project.id}/reports`);
    }
  };

  const shareReport = async () => {
    await shareProjectReport({
      variables: { projectReportId: data?.projectReport?.id, share: !data?.projectReport?.shared },
      refetchQueries: ['GetProjectReportView']
    });
    setIsVerifyingShare(false);
  };

  if (error) {
    return (
      <Notice noticeColor="red.500" w="100%">
        <Text>There was an error loading your reports.</Text>
      </Notice>
    );
  }

  return (
    <QueryContextProvider>
      <ReactLink
        style={{ alignSelf: 'start', color: 'var(--chakra-colors-blue-500)' }}
        to={`/project/${project.id}/reports`}>
        <ArrowLeftIcon pr="5px" />
        Return to Reports
      </ReactLink>
      <Card mb="20px">
        <CardBody>
          <HStack w="100%">
            <VStack alignItems="start" w="50%">
              <Info name="Description" value={data?.projectReport?.description} />
              <Info
                name="Created By"
                value={
                  isEmpty(data?.projectReport?.createdBy?.name)
                    ? data?.projectReport?.createdBy?.email
                    : data.projectReport.createdBy.name
                }
              />
              <Info
                name="Published"
                value={
                  data?.projectReport?.published
                    ? format(new Date(data?.projectReport?.published), 'PP')
                    : 'Draft'
                }
              />
              <Info
                name="Shared"
                value={data?.projectReport?.shared ? 'Shared within project' : 'Not Shared'}
              />
            </VStack>
          </HStack>
        </CardBody>
        {data?.projectReport.createdBy.oryId === user.oryId && (
          <CardFooter justifyContent="space-between">
            <Button
              data-cypress="share-report"
              colorScheme={data?.projectReport?.shared ? 'orange' : 'blue'}
              leftIcon={data?.projectReport?.shared ? <CloseIcon /> : <BiShare />}
              onClick={() => setIsVerifyingShare(true)}>
              {data?.projectReport?.shared ? 'Revoke Share' : 'Share'}
            </Button>

            <Button
              data-cypress="delete-report"
              colorScheme={isVerifyingDelete ? 'white' : 'red'}
              color={isVerifyingDelete ? 'red.500' : 'white'}
              borderColor={isVerifyingDelete && 'red.500'}
              border={isVerifyingDelete && '1px solid'}
              leftIcon={<DeleteIcon />}
              onClick={verifyDelete}>
              {isVerifyingDelete ? 'Confirm Delete?' : 'Delete'}
            </Button>
          </CardFooter>
        )}
      </Card>
      <Box w="100%">
        {!data?.projectReport.published && (
          <Alert status="info" variant="left-accent">
            <AlertIcon mb="5px" />
            This report is currently in the draft stage. It is not finalized and may change!
          </Alert>
        )}
        {/*
           //@ts-ignore */}
        <ReportingViewProvider report={data?.projectReport}>
          <FancyEditor editor={editor} />
        </ReportingViewProvider>
      </Box>
      <AlertDialog
        isCentered={true}
        leastDestructiveRef={undefined}
        isOpen={isVerifyingShare}
        onClose={() => setIsVerifyingShare(false)}>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Confirm {data?.projectReport?.shared ? 'Revoke' : 'Share'}
          </AlertDialogHeader>

          <AlertDialogBody>
            {data?.projectReport?.shared ? (
              <Text>
                Revoking this share will remove the report from others &quot;Shared Reports&quot;
                and will be hidden from other users.
              </Text>
            ) : (
              <Text>Sharing this report will make it accessible to all users in your project.</Text>
            )}
          </AlertDialogBody>

          <AlertDialogFooter justifyContent="space-between">
            <Button onClick={() => setIsVerifyingShare(false)}>Cancel</Button>
            <Button data-cypress="confirm-share" colorScheme="blue" onClick={shareReport}>
              {data?.projectReport?.shared ? 'Revoke' : 'Share'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </QueryContextProvider>
  );
};

export default ReportsView;
