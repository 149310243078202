// @ts-strict
import { ApolloProvider } from '@apollo/client';
import { Center } from '@chakra-ui/react';
import cubejs, { CubeApiOptions } from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import * as Sentry from '@sentry/react';
import { EquipmentAdminLayout } from 'components/Layouts/EquipmentAdminLayout';
import MainLayout from 'components/Layouts/MainLayout';
import PageLayout from 'components/Layouts/PageLayout';
import ProjectLayout from 'components/Layouts/ProjectLayout';
import SiteLayout from 'components/Layouts/SiteLayout';
import DownForMaintenance from 'components/Pages/DownForMaintenance';
import NotFound from 'components/Pages/NotFound';
import { RenderError } from 'components/Pages/RenderError';
import { ProjectContextProvider } from 'contexts/ProjectContext';
import SessionContextProvider from 'contexts/SessionContext';
import { UserContextProvider } from 'contexts/UserContext';
import 'mapbox-gl';
import DataImport from 'pages/DataImport';
import Docs from 'pages/Docs';
import IndexRedirect from 'pages/IndexRedirect';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import ProjectId from 'pages/project/[Id]';
import { EquipmentAdmin } from 'pages/project/[Id]/Admin/Equipment/EquipmentAdmin';
import { EquipmentAssign } from 'pages/project/[Id]/Admin/Equipment/EquipmentAssign';
import { UsersAdmin } from 'pages/project/[Id]/Admin/UsersAdmin';
import AlarmIndex from 'pages/project/[Id]/Alarm/Index';
import NotificationList from 'pages/project/[Id]/Alarm/NotificationList';
import { ProjectPlankton } from 'pages/project/[Id]/Config/Plankton';
import ProjectTabId from 'pages/project/[Id]/overview/[tabId]';
import ProjectPaneId from 'pages/project/[Id]/overview/[tabId]/[paneId]';
import ProjectOverviewId from 'pages/project/[Id]/overview/Index';
import SiteId from 'pages/project/[Id]/site/[Id]';
import PopulationAnalysisId from 'pages/project/[Id]/site/[Id]/PopulationAnalysisId';
import TabId from 'pages/project/[Id]/site/[Id]/[tabId]';
import PaneId from 'pages/project/[Id]/site/[Id]/[tabId]/[paneId]';
import ProjectIndex from 'pages/project/Index';
import ProjectPicker from 'pages/project/Picker';
import { ProjectSuspended } from 'pages/project/Suspended';
import Recovery from 'pages/Recovery';
import StartRecovery from 'pages/StartRecovery';
import {
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useLocation
} from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import 'tachyons/css/tachyons.css';
import './App.css';
import config from './config';
import { client } from './graphql/apolloClient';
import ReportsIndex from 'pages/project/[Id]/reports/Index';
import ProjectAdminLayout from 'pages/project/[Id]/Admin/ProjectAdminLayout';
import ReportsIdEdit from 'pages/project/[Id]/reports/Edit';
import ReportsAll from 'pages/project/[Id]/reports/All';
import ReportsShared from 'pages/project/[Id]/reports/Shared';
import ReportsView from 'pages/project/[Id]/reports/View';
import FishGroupsTimeline from 'pages/project/[Id]/fish-groups/Edit';
import FishGroupsIndex from 'pages/project/[Id]/fish-groups/Index';
import FishGroupsId from 'pages/project/[Id]/fish-groups/[Id]';
import ProjectIdEdit from 'pages/project/[Id]/Edit';
import SiteIdEdit from 'pages/project/[Id]/site/[Id]/Edit';
import FishGroupsTabId from 'pages/project/[Id]/fish-groups/FishGroupsTabId';
import FishGroupsLayout from 'components/Layouts/FishGroupsLayout';
import ReportsLayout from 'components/Layouts/ReportLayout';

export const cubejsApiOptions: CubeApiOptions = {
  apiUrl: config.api.CUBE_API_ENDPOINT,
  pollInterval: 300,
  parseDateMeasures: true,
  credentials: 'include'
};

export const cubejsApi = cubejs(cubejsApiOptions);

//Navigate from 'legacy' /dashboard urls that aren't needed for a while.
const DashboardRenavigate = () => {
  const location = useLocation();
  return <Navigate to={location.pathname.replace('dashboard/', '')} replace={true} />;
};

const DOWN = false;

const router = createBrowserRouter(
  createRoutesFromElements(
    DOWN ? (
      <Route path="/*" element={<DownForMaintenance />} />
    ) : (
      <Route element={<SessionContextProvider />}>
        <Route element={<UserContextProvider />}>
          <Route element={<ProjectContextProvider />}>
            <Route errorElement={<RenderError />} path="/">
              <Route index element={<IndexRedirect />} />
              <Route path="recovery" element={<Recovery />} />
              <Route path="start-recovery" element={<StartRecovery />} />
              <Route element={<IndexRedirect />} />
              <Route path="under-maintenance" element={<DownForMaintenance />} />
              <Route path="login" element={<Login />} />
              <Route path="logout" element={<Logout />} />
              <Route path="dashboard/*" element={<DashboardRenavigate />} />
              <Route path="suspended" element={<ProjectSuspended />} />
              <Route element={<MainLayout />}>
                <Route path="docs" element={<Docs />} />
                <Route path="import" element={<DataImport />} />
                <Route path="project">
                  <Route index element={<ProjectIndex />} />
                  <Route path="picker" element={<ProjectPicker />} />
                  <Route path=":projectId/edit" element={<ProjectIdEdit />} />
                  <Route errorElement={<RenderError />} element={<PageLayout />}>
                    {/* Need tab layout for Regional comparison */}
                    <Route element={<ProjectLayout />}>
                      <Route path=":projectId" element={<ProjectId />} />
                      <Route path=":projectId/fish-groups" element={<FishGroupsIndex />} />
                      <Route
                        path=":projectId/fish-groups/:fishGroupId/timeline"
                        element={<FishGroupsTimeline />}
                      />
                      <Route path=":projectId/overview" element={<ProjectOverviewId />} />
                      <Route path=":projectId/overview/:tabId" element={<ProjectTabId />}>
                        <Route path=":paneId" element={<ProjectPaneId />} />
                      </Route>
                      <Route
                        path=":projectId/reports/:reportId/edit/:step"
                        element={<ReportsIdEdit />}
                      />
                      <Route path=":projectId/reports" element={<ReportsLayout />}>
                        <Route index element={<ReportsIndex />} />
                        <Route path="shared" element={<ReportsShared />} />
                        <Route path="all" element={<ReportsAll />} />
                      </Route>
                    </Route>
                    <Route path=":projectId/config/plankton" element={<ProjectPlankton />} />
                    <Route path=":projectId/reports/:reportId/view" element={<ReportsView />} />
                    <Route path=":projectId/admin" element={<ProjectAdminLayout />}>
                      <Route path="users" element={<UsersAdmin />} />
                      <Route path="equipment" element={<EquipmentAdminLayout />}>
                        <Route path="manage" element={<EquipmentAdmin />} />
                        <Route path="assign" element={<EquipmentAssign />} />
                      </Route>
                    </Route>
                    <Route path=":projectId/alarm" element={<AlarmIndex />} />
                    <Route
                      path=":projectId/alarm/notification-list"
                      element={<NotificationList />}
                    />
                    <Route path=":projectId/site/:siteId/edit" element={<SiteIdEdit />} />
                    <Route element={<FishGroupsLayout />}>
                      <Route
                        path=":projectId/fish-groups/:fishGroupId"
                        element={<FishGroupsId />}
                      />
                      <Route
                        path=":projectId/fish-groups/:fishGroupId/:tabId"
                        element={<FishGroupsTabId />}
                      />
                    </Route>
                    <Route
                      path=":projectId/site/:siteId/population/:sublocationId"
                      element={<PopulationAnalysisId />}
                    />
                    <Route element={<SiteLayout />}>
                      <Route path=":projectId/site/:siteId" element={<SiteId />} />
                      <Route path=":projectId/site/:siteId/:tabId" element={<TabId />}>
                        <Route path=":paneId" element={<PaneId />} />
                      </Route>
                    </Route>
                    <Route path="*" element={<NotFound />} />
                  </Route>
                </Route>
              </Route>

              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Route>
      </Route>
    )
  )
);

export function App() {
  return (
    <Sentry.ErrorBoundary
      fallback={
        <Center width={'100vw'} height={'100vh'}>
          <RenderError />
        </Center>
      }
      showDialog={false}>
      <ApolloProvider client={client}>
        <CubeProvider cubeApi={cubejsApi}>
          <RouterProvider router={router} />
        </CubeProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}
