import { ArrowRightIcon, CheckIcon } from '@chakra-ui/icons';
import { useContext } from 'react';
import { ReportingEditContext } from './ReportingEditContext';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogContent,
  Alert,
  AlertIcon,
  Button,
  ButtonGroup,
  Text,
  Card,
  CardBody,
  Spacer,
  VStack,
  useToast
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from 'contexts/ProjectContext';
import { omit } from 'lodash';

const ReportNav = () => {
  const navigate = useNavigate();
  const project = useContext(ProjectContext);
  const toast = useToast();

  const {
    reportId,
    activeStep,
    goToPrevious,
    steps,
    goToNext,
    showingFinalizeModal,
    setShowingFinalizeModal,
    updateProjectReport,
    reportInfo,
    queryData,
    nextIsDisabled,
    editor
  } = useContext(ReportingEditContext);

  const saveAndStep = async () => {
    try {
      // if (activeStep === 1) {
      //   await updateProjectReport({
      //     variables: {
      //       projectReportId: reportId,
      //       projectReport: {
      //         type: selectedReportTemplate.id
      //       }
      //     },
      //     refetchQueries: ['GetProjectReport']
      //   });
      // }

      if (activeStep === 1) {
        const documentContent = editor.getJSON();

        if (
          documentContent.content
            .filter((c) => c.type === 'plotlyComponent')
            .some((c) => c.attrs.loading)
        ) {
          toast({
            status: 'error',
            description: 'Please wait until all charts are loaded to continue.'
          });
          return;
        }

        const filteredContent = documentContent.content.map((c) => {
          if (c.type !== 'plotlyComponent') return c;

          const chart = c.attrs?.chart;

          if (!chart) return;

          const chartSettings = {
            chartId: chart.chartId ?? chart.id,
            displayName: chart.displayName,
            settings: {
              projectId: chart.settings?.project?.id ?? chart.settings?.projectId,
              siteId: chart.settings?.site?.id,
              ...omit(chart.settings, 'site', 'project')
            }
          };

          return { ...c, attrs: { chart: chartSettings } };
        });

        await updateProjectReport({
          variables: {
            projectReportId: reportId,
            projectReport: {
              type: 'SEASTATE',
              content: { ...documentContent, content: filteredContent }
            }
          },
          refetchQueries: ['GetProjectReport', 'GetProjectReports']
        });
      }

      if (activeStep === steps.length) {
        setShowingFinalizeModal(true);
        return;
      }

      goToNext();
    } catch (e) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Error saving report'
      });
    }
  };

  const saveAndNav = async () => {
    const projectReport = queryData?.data?.projectReport;
    await updateProjectReport({
      variables: {
        projectReportId: projectReport.id,
        projectReport: {
          type: projectReport.type,
          name: reportInfo?.name ?? projectReport.name,
          description: reportInfo?.description ?? projectReport.description,
          content: projectReport.content,
          publish: true
        }
      }
    });
    navigate(`/project/${project.id}/reports`);
  };

  return (
    <>
      <Card position="sticky" bottom="0px" w="100%" p="0px">
        <CardBody p="10px">
          <ButtonGroup w="100%" justifyContent="space-between">
            {activeStep == 1 ? <Spacer /> : <Button onClick={goToPrevious}>Previous</Button>}
            <Button
              data-cypress="report-next-step"
              isLoading={!queryData?.data?.projectReport}
              isDisabled={!queryData?.data?.projectReport || nextIsDisabled}
              rightIcon={activeStep === steps.length ? <CheckIcon /> : <ArrowRightIcon />}
              colorScheme="blue"
              onClick={saveAndStep}>
              {activeStep === steps.length ? 'Finish' : 'Next'}
            </Button>
          </ButtonGroup>
        </CardBody>
      </Card>
      {showingFinalizeModal && (
        <AlertDialog
          size="lg"
          isOpen={true}
          onClose={() => setShowingFinalizeModal(false)}
          leastDestructiveRef={undefined}
          isCentered={true}>
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>Confirm Report Content</AlertDialogHeader>
            <AlertDialogBody>
              <VStack w="100%">
                <Text>Are you sure your report is correct?</Text>
                <Text display="inline">
                  Once a report is created you{' '}
                  <Text display="inline" fontWeight="bold">
                    cannot
                  </Text>{' '}
                  modify the content!
                </Text>
                <Alert status="info" variant="left-accent">
                  <AlertIcon mb="5px" />
                  <VStack w="100%">
                    <Text display="inline">
                      Any relative times on charts will be published at this time and{' '}
                      <Text display="inline" fontWeight="bold">
                        will not update
                      </Text>{' '}
                      in the future.
                    </Text>
                    <Text>
                      Ex. &apos;Last Month&apos; will remain whatever the current values are at this
                      time. If you wish to later update the report, please make a new copy.
                    </Text>
                  </VStack>
                </Alert>
              </VStack>
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup w="100%" justifyContent="space-between">
                <Button
                  onClick={() => {
                    setShowingFinalizeModal(false);
                  }}>
                  Cancel
                </Button>
                <Button
                  data-cypress="confirm-report-creation"
                  colorScheme="blue"
                  onClick={saveAndNav}>
                  Looks Good!
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </>
  );
};

export default ReportNav;
