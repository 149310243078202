import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  Text,
  VStack,
  Wrap
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';
import {
  differenceInDays,
  eachMonthOfInterval,
  endOfMonth,
  format,
  getDaysInMonth,
  max,
  min,
  startOfMonth
} from 'date-fns';
import { ReactElement, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Notice } from './Notice';
import { isEmpty, sortBy, uniqBy } from 'lodash';
import { createLocationPallet } from 'shared/functions/colorPallets';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import BaseInput from './Forms/BaseInput';
import './PopulationsTimeline.css';
import { IconType } from 'react-icons';
import { permissions } from '@scoot/permissions';
import { UserContext } from 'contexts/UserContext';
import { ProjectContext } from 'contexts/ProjectContext';
import { PopulationEventType } from 'pages/project/[Id]/fish-groups/Edit';
import { debounce } from 'lodash';

type TimelinePopulation = {
  id: string;
  start: Date;
  end: Date;
  name: string;
  group: string;
  tags?: string;
  siteId: number;
  sublocation: string;
};

type PopulationsTimelineProps = {
  populations: TimelinePopulation[];
  events?: {
    id: string;
    date: Date;
    group: string;
    type: PopulationEventType;
    details: React.FC;
    icon: IconType;
  }[];
  initialPopulations?: TimelinePopulation[];
  arrows: ReactElement;
  initialName?: string;
  onLoadMore: () => void;
  onSaveFishGroup: ({
    name,
    populationIds
  }: {
    name: string;
    populationIds: string[];
  }) => Promise<any>;
};

const DAY_WIDTH = 10;
const ROW_HEIGHT = 26;

export const Z_INDICES = {
  icons: 1,
  arrows: 2,
  editButton: 4,
  headers: 3,
  footerCard: 5,
  popover: 6
};

export const PopulationsTimeline = ({
  populations,
  events,
  arrows,
  initialName,
  initialPopulations,
  onSaveFishGroup,
  onLoadMore
}: PopulationsTimelineProps) => {
  const userContext = useContext(UserContext);
  const project = useContext(ProjectContext);
  const [groupFilter, setGroupFilter] = useState('');
  const [isCreatingPopulation, setIsCreatingFishGroup] = useState(false);
  const [buildingFishGroup, setBuildingFishGroup] = useState<TimelinePopulation[]>(
    initialPopulations ?? []
  );
  const [fishGroupName, setFishGroupName] = useState(initialName ?? '');

  const timelineContainerRef = useRef<HTMLDivElement>(null);

  const filteredPopulations = useMemo(() => {
    return populations.filter((p) => p.group.toLowerCase().includes(groupFilter.toLowerCase()));
  }, [populations, groupFilter]);

  useEffect(() => {
    const container = timelineContainerRef.current;
    if (!container) return;

    container.scrollTo({ left: container.scrollWidth });
  }, [timelineContainerRef]);

  const isNew = useMemo(() => initialPopulations.length === 0, [initialPopulations]);

  const [startMonth, endMonth] = useMemo(() => {
    const minDate = min(populations.map((e) => e.start));
    const maxDate = max(populations.map((e) => e.end));

    return [startOfMonth(minDate), endOfMonth(maxDate)];
  }, [populations]);

  const calculateMonthPosition = useCallback(
    (startDate: Date) => {
      const days = differenceInDays(startDate, startMonth);
      return days * DAY_WIDTH;
    },
    [startMonth]
  );

  const calculateMonthWidth = (month: Date) => getDaysInMonth(month) * DAY_WIDTH;

  const calculateEventWidth = (start: Date, end: Date) => {
    const width = calculateMonthPosition(end) - calculateMonthPosition(start);
    if (width === 0) return DAY_WIDTH;
    return width;
  };

  const rowHeaders = useMemo(
    () => uniqBy(sortBy(filteredPopulations, 'group'), 'group'),
    [filteredPopulations]
  );

  const pallet = useMemo(
    () => createLocationPallet({ locations: rowHeaders.map((rh) => rh.group) }),
    [rowHeaders]
  );

  const populationRowOnClick = (population: TimelinePopulation) => {
    if (!isCreatingPopulation) return;
    if (buildingFishGroup.includes(population)) {
      setBuildingFishGroup(buildingFishGroup.filter((p) => p !== population));
    } else {
      setBuildingFishGroup([...buildingFishGroup, population]);
    }
  };

  const TimelineEvents = useMemo(() => {
    return rowHeaders.map((rh, i) =>
      events
        .filter((ev) => ev.group === rh.group)
        .map((e) => {
          return (
            <>
              <Popover key={`${e.id}-${e.type}`} trigger="hover">
                <PopoverTrigger>
                  <Box
                    zIndex={Z_INDICES.icons}
                    position="absolute"
                    top={`${i * ROW_HEIGHT}px`}
                    left={`${calculateMonthPosition(e.date) - 12}px`}>
                    <e.icon
                      key={e.id}
                      id={e.id}
                      style={{
                        opacity: 0.5,
                        backgroundColor: 'white',
                        fontSize: '24px',
                        border: '2px solid gray',
                        borderRadius: '100%'
                      }}
                    />
                  </Box>
                </PopoverTrigger>
                <PopoverContent zIndex={Z_INDICES.popover} w="fit-content">
                  <PopoverBody textAlign="left">
                    <e.details />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </>
          );
        })
    );
  }, [events, rowHeaders, calculateMonthPosition, ROW_HEIGHT, arrows]);

  //NOTE: Crunching the render on this takes some time, so tread lightly with updates if possible
  const Timeline = useMemo(() => {
    return (
      <HStack ref={timelineContainerRef} spacing={0} alignItems="start" w="100%" overflow="scroll">
        <VStack zIndex={Z_INDICES.headers} id="vstack0" position="sticky" left={0} spacing="0">
          <Box h={ROW_HEIGHT} backgroundColor="transparent"></Box>
          {rowHeaders.map((rh) => (
            <Box
              textAlign="center"
              h={ROW_HEIGHT}
              backgroundColor="white"
              w="200px"
              border="1px solid"
              borderRadius="5px"
              borderColor="gray.300"
              key={rh.group}>
              {userContext.superuser ? (
                <ReactLink
                  to={`/project/${project.id}/site/${rh.siteId}/population/${rh.sublocation}`}>
                  <Text>{rh.group}</Text>
                </ReactLink>
              ) : (
                <Text>{rh.group}</Text>
              )}
            </Box>
          ))}
        </VStack>
        <VStack id="vstack1" w="fit-content" spacing="0">
          <HStack position="sticky" top={0} id="hstack1" w="fit-content" spacing="0">
            {eachMonthOfInterval({ start: startMonth, end: endMonth }).map((monthDate) => {
              return (
                <Box
                  textAlign="center"
                  w={calculateMonthWidth(monthDate)}
                  borderRadius="5px"
                  border="1px solid"
                  borderColor="gray.300"
                  key={monthDate.toISOString()}>
                  {format(monthDate, 'MMMM y')}
                </Box>
              );
            })}
          </HStack>
          <Box id="cells-area" position="relative" w="100%" h="100%">
            {TimelineEvents}
            {arrows}
            {rowHeaders.map((rh, i) =>
              filteredPopulations
                .filter((e) => e.group === rh.group)
                .map((e) => {
                  return (
                    <Popover id={e.id} key={e.name} trigger="hover">
                      <PopoverTrigger>
                        <Box
                          id={e.id}
                          border="1px solid"
                          borderRadius="5px"
                          borderColor="gray.300"
                          className={buildingFishGroup.includes(e) ? 'population--selected' : ''}
                          onClick={() => populationRowOnClick(e)}
                          minH={ROW_HEIGHT}
                          overflow="hidden"
                          backgroundColor={`${pallet[rh.group]} !important`}
                          position="absolute"
                          top={`${i * ROW_HEIGHT}px`}
                          left={`${calculateMonthPosition(e.start)}px`}
                          width={`${calculateEventWidth(e.start, e.end)}px}`}
                          key={e.name}></Box>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverBody textAlign="left">
                          <VStack>
                            <Text fontWeight="bold">{rh.group}</Text>
                            <Text>
                              {format(e.start, 'PP')} - {format(e.end, 'PP')}
                            </Text>
                            <Text>{e?.tags}</Text>
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  );
                })
            )}
          </Box>
        </VStack>
      </HStack>
    );
  }, [
    timelineContainerRef,
    rowHeaders,
    startMonth,
    endMonth,
    filteredPopulations,
    setBuildingFishGroup,
    buildingFishGroup,
    isCreatingPopulation
  ]);

  const debouncedFilter = useRef(
    debounce((filterString) => {
      setGroupFilter(filterString);
    }, 200)
  ).current;

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedFilter(e.target.value);
  };

  return (
    <>
      <BaseInput placeholder="Site filter..." onChange={onFilterChange} />

      {filteredPopulations.length === 0 ? (
        <Notice>
          <Text>No Events</Text>
        </Notice>
      ) : (
        <VStack pb="50px" w="100%" alignItems="start">
          {Timeline}
        </VStack>
      )}
      {isCreatingPopulation ? (
        <Card
          border="1px solid"
          borderColor="blue.300"
          zIndex={Z_INDICES.footerCard}
          w="100%"
          position="sticky"
          bottom={0}>
          <CardHeader>
            <HStack>
              <BaseInput
                data-cypress="fish-group-name-input"
                value={fishGroupName}
                onChange={(e) => setFishGroupName(e.currentTarget.value)}
                w="100%"
                label="Fish Group Name"
              />
              <IconButton
                size="sm"
                onClick={() => setIsCreatingFishGroup(false)}
                icon={<CloseIcon />}
                aria-label={'Close create fish group'}
              />
            </HStack>
          </CardHeader>
          <CardBody maxH="200px" overflowY="scroll">
            {buildingFishGroup.length === 0 && (
              <Notice>
                <Text>No pen traces selected.</Text>
              </Notice>
            )}
            <Wrap>
              {buildingFishGroup.map((p) => (
                <Tag backgroundColor={pallet[p.group]} key={p.id}>
                  {p.group}: {format(p.start, 'PP')} - {format(p.end, 'PP')}
                </Tag>
              ))}
            </Wrap>
          </CardBody>
          <CardFooter>
            <Button
              data-cypress="save-fish-group-button"
              onClick={() =>
                onSaveFishGroup({
                  name: fishGroupName,
                  populationIds: buildingFishGroup.map((bp) => bp.id)
                })
              }
              isDisabled={buildingFishGroup.length === 0 || isEmpty(fishGroupName)}
              colorScheme="green"
              rightIcon={<AddIcon />}>
              {isNew ? 'Create' : 'Save'} Fish Group
            </Button>
          </CardFooter>
        </Card>
      ) : (
        <HStack zIndex={Z_INDICES.editButton} position="sticky" bottom={10}>
          <Button onClick={onLoadMore}>Load More</Button>
          {permissions.canAdminProject(userContext, project.id) && (
            <Button
              data-cypress={'edit-population-button'}
              colorScheme="blue"
              rightIcon={<AddIcon />}
              onClick={() => setIsCreatingFishGroup(true)}>
              {isNew ? 'Create' : 'Edit'} Population
            </Button>
          )}
        </HStack>
      )}
    </>
  );
};
