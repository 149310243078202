import { FishGroupsOutletContext } from 'components/Layouts/FishGroupsLayout';
import { ProjectContext } from 'contexts/ProjectContext';
import { useContext, useMemo } from 'react';
import { Navigate, useOutletContext, useParams, useNavigate } from 'react-router-dom';
import { TabErrorBoundary } from 'components/Layouts/SiteLayout';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Tab,
  TabList,
  Tabs
} from '@chakra-ui/react';

import FishHealth from 'components/Site/Tabs/FishHealth/FishHealth';
import Environment from 'components/Site/Tabs/Environment/Environment';
import Feed from 'components/Site/Tabs/Feed/Feed';
import SeaLiceData from 'components/Site/Tabs/SeaLice/SeaLiceData';
import Explore from 'components/Site/Tabs/Explore/Explore';
import HeadlineStats from 'components/Site/Headline/Stats/HeadlineStats';
import { QueryContext } from 'contexts/QueryContext';

export const fishGroupTabs = [
  {
    slug: 'environment',
    element: Environment,
    displayName: 'Environment'
  },
  {
    slug: 'fish-health',
    element: FishHealth,
    displayName: 'Fish Health'
  },
  {
    slug: 'feed',
    element: Feed,
    displayName: 'Feed'
  },
  {
    slug: 'sea-lice',
    element: SeaLiceData,
    displayName: 'Sea Lice'
  },
  {
    slug: 'explore',
    element: Explore,
    displayName: 'Explore'
  }
];

const FishGroupsTabId = () => {
  const project = useContext(ProjectContext);
  const { staleFishGroup } = useContext(QueryContext);
  const { fishGroup } = useOutletContext<FishGroupsOutletContext>();
  const { tabId } = useParams();
  const navigate = useNavigate();

  const availableTabs = useMemo(
    () => (staleFishGroup ? [fishGroupTabs.find((t) => t.slug === 'explore')] : fishGroupTabs),
    [staleFishGroup]
  );

  const tab = fishGroupTabs.find((tab) => tab.slug === tabId);
  const selectedIdx = fishGroupTabs.findIndex((fgt) => fgt.slug === tabId) ?? 0;

  const tabUrl = (tab: string) => `/project/${project.id}/fish-groups/${fishGroup.id}/${tab}`;

  if (selectedIdx === -1) {
    return (
      <Navigate
        to={`/project/${project.id}/fish-groups/${fishGroup.id}/${fishGroupTabs[0].slug}`}
        replace={true}
      />
    );
  }

  if (staleFishGroup && tab.slug !== 'explore') {
    return (
      <Navigate to={`/project/${project.id}/fish-groups/${fishGroup.id}/explore`} replace={true} />
    );
  }

  return (
    <>
      {staleFishGroup ? (
        <Alert>
          <AlertIcon />
          <AlertTitle>Fish Group Older Than 1 Month</AlertTitle>
          <AlertDescription>
            Because this fish group has ended more than a month ago, and has not had recent
            activity, only the Explore tab will be shown.
          </AlertDescription>
        </Alert>
      ) : (
        <HeadlineStats />
      )}
      <Tabs w="100%" index={selectedIdx} defaultIndex={0}>
        <TabList mb="20px">
          {availableTabs.map((tab) => (
            <Tab
              onClick={() => navigate(tabUrl(tab.slug))}
              key={tab.slug}
              data-cypress={`fish-groups-tab-${tab.displayName.toLowerCase().replace(' ', '-')}`}>
              {tab.displayName}
            </Tab>
          ))}
        </TabList>

        {/*
          // @ts-ignore */}
        <TabErrorBoundary>
          <tab.element project={project} />
        </TabErrorBoundary>
      </Tabs>
    </>
  );
};

export default FishGroupsTabId;
