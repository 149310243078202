import { CubeProvider } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import { createContext, FC, useContext } from 'react';
import { cubejsApiOptions } from 'App';
import { ProjectContext } from 'contexts/ProjectContext';
import { ProjectReport } from 'graphql/generated';
import { UserContext } from 'contexts/UserContext';

export const ReportingViewContext = createContext(null);

type ReportingViewProviderProps = {
  report?: Partial<ProjectReport>;
};

export const ReportingViewProvider: FC<ReportingViewProviderProps> = ({
  children,
  report
}) => {
  const project = useContext(ProjectContext);
  const user = useContext(UserContext);

  let headers: {
    projectId: string,
    viewingProjectReportId?: string
  } = {
    projectId: project.id.toString(),
  };

  if(report?.shared && user.oryId !== report.createdBy.oryId) {
    headers = { ...headers, viewingProjectReportId: report.id.toString() }
  }

  return (
    <CubeProvider
      cubeApi={cubejs({
        ...cubejsApiOptions,
        headers
      })}>
      {children}
    </CubeProvider>
  );
};
